import * as moment from 'moment';
import { concat, contains, defaultTo, each, get, lowerCase, map, reduce } from 'lodash/fp';
import { TasksTagsService } from '../../filter/tags/tags.service';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import analytics, { AnalyticsService } from '../../../common/analytics/analytics.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../tasks.service';

export class LogTaskController {
    comment: any; // addModal type issue w/ string
    status: string;
    task: any;
    contactsList: any[];
    showAllFields = false;
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private accounts: AccountsService, // used in view
        private analytics: AnalyticsService,
        private contacts: ContactsService,
        private serverConstants: ServerConstantsService,
        private tasks: TasksService,
        private tasksTags: TasksTagsService, // used in view
        contactsList: any[],
    ) {
        this.task = {
            completed: true,
            completed_at: moment().toISOString(),
        };
        this.contactsList = contactsList;
        this.activityChanged();
    }
    save() {
        return this.getPromise().then(() => {
            let task = angular.copy(this.task);
            if (this.contactsList.length === 0) {
                this.analytics.dispatch('mpdx-task-completed');
            } else {
                each(() => this.analytics.dispatch('mpdx-task-completed'), this.contactsList);
            }
            this.$scope.$hide();
            if (get('next_action', task)) {
                task.subject = task.next_action === task.activity_type ? task.subject : null;
                this.tasks.addModal({
                    activityType: task.next_action,
                    comments: [this.comment],
                    contactsList: map('id', this.contactsList),
                    task,
                });
            } else {
                this.$rootScope.$emit('taskLogged');
            }
        });
    }
    getPromise() {
        const taskPromise = this.createTask();
        const contactPromise = this.getContactPromise();
        return contactPromise ? this.$q.all([taskPromise, contactPromise]) : taskPromise;
    }
    createTask() {
        const contactIdList = map('id', this.contactsList);
        return this.tasks.create(this.task, contactIdList, this.comment);
    }
    getContactPromise() {
        const contactIdList = map('id', this.contactsList);
        return this.status && this.showPartnerStatus()
            ? this.contacts.bulkSave(
                  reduce((result, contact) => concat(result, { id: contact, status: this.status }), [], contactIdList),
              )
            : false;
    }
    showPartnerStatus() {
        return (
            this.contactsList.length > 0 &&
            this.task.activity_type &&
            !contains(this.task.activity_type, [
                'Pre Call Letter',
                'Reminder Letter',
                'Support Letter',
                'Thank',
                'To Do',
            ])
        );
    }
    activityChanged() {
        const activityType = lowerCase(defaultTo('default', this.task.activity_type));
        const results = this.serverConstants.data.results[activityType];
        this.task.result = get('[0]', results);
    }
}

export default angular
    .module('mpdx.contacts.logTask.controller', [accounts, analytics, contacts, serverConstants, tasks])
    .controller('logTaskController', LogTaskController).name;
