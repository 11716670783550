import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../../common/api/api.service';
import users, { UsersService } from '../../../../common/users/users.service';

class RecommendationController {
    contactId: string;
    isUS: boolean;
    recommendation: any;
    appName = process.env.APP_NAME || 'MPDX';
    constructor(
        private $log: ng.ILogService,
        private accounts: AccountsService, // used in view
        private api: ApiService,
        private users: UsersService, // used in view
    ) {}
    $onChanges(): ng.IPromise<void> | void {
        this.recommendation = null;
        if (this.contactId) {
            return this.users.listOrganizationAccounts().then(() => {
                this.isUS = this.users.isCruUS();
                if (this.canView()) {
                    this.getRecommendation();
                }
            });
        }
    }
    getRecommendation(): ng.IPromise<void> {
        return this.api
            .get({
                url: `contacts/${this.contactId}/donation_amount_recommendations`,
                data: {
                    page: 1,
                    per_page: 1,
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug('recommendation by contact', this.contactId, data[0]);
                this.recommendation = data[0];
            });
    }
    canView(): boolean {
        return (this.accounts.current.tester || this.users.current.preferences.admin) && this.isUS;
    }
}

const Recommendation = {
    template: require('./recommendation.html'),
    controller: RecommendationController,
    bindings: {
        contactId: '<',
    },
};

export default angular
    .module('mpdx.contacts.show.details.recommendation.component', [accounts, api, users])
    .component('contactRecommendation', Recommendation).name;
