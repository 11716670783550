import { Cancelable } from 'lodash';
import { debounce, defaultTo, get, reduce } from 'lodash/fp';
import { IAddress } from '../types';
import addressFinder, { AddressFinderService } from './addressFinder.service';

export class AddressFinderController {
    predictions: google.maps.places.AutocompletePrediction[];
    debounced: (() => void) & Cancelable;
    loading: boolean;
    autocompleteService: google.maps.places.AutocompleteService;
    sessionToken: google.maps.places.AutocompleteSessionToken;
    placesService: google.maps.places.PlacesService;
    street: string;
    address: IAddress;
    update: ({
        address,
        result,
        street,
    }: {
        address?: IAddress;
        result?: google.maps.places.PlaceResult;
        street?: string;
    }) => void;
    editable: boolean;
    autofocus: boolean;
    constructor(
        private $document: ng.IDocumentService,
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private addressFinder: AddressFinderService,
    ) {
        this.predictions = [];
        this.debounced = debounce(1500, this.getPredictions);
    }
    $onInit() {
        this.autocompleteService = new this.$window.google.maps.places.AutocompleteService();
        this.sessionToken = new this.$window.google.maps.places.AutocompleteSessionToken();
        this.street = get('street', this.address);
        this.$timeout(() => {
            this.placesService = new this.$window.google.maps.places.PlacesService(
                this.$document[0].getElementById(`google_places_attribution_container_${this.$scope.$id}`),
            );
        });
    }
    keyDown(event: KeyboardEvent): void {
        if (event.key === 'Tab') {
            this.reset();
        }
    }
    change(): void {
        if (this.valid()) {
            this.loading = true;
            this.debounced();
        } else {
            this.reset();
        }
        this.update({ street: this.street });
    }
    valid({ ignoreCurrent } = { ignoreCurrent: false }): boolean {
        return (
            this.street &&
            this.street !== '' &&
            this.street.indexOf('\n') === -1 &&
            (this.street !== get('street', this.address) || ignoreCurrent) &&
            !this.addressFinder.cache[this.street]
        );
    }
    reset({ ignoreCache } = { ignoreCache: false }): void {
        this.predictions = ignoreCache ? [] : this.addressFinder.cache[this.street] || [];
        this.loading = false;
        this.debounced.cancel();
    }
    getPredictions(): void {
        if (this.valid({ ignoreCurrent: true }) && get('getPlacePredictions', this.autocompleteService)) {
            const street = this.street;
            this.autocompleteService.getPlacePredictions(
                {
                    input: street,
                    sessionToken: this.sessionToken,
                },
                (results, status) => {
                    if (status === 'OK') {
                        this.addressFinder.cache[street] = results;
                    }
                    if (this.street === street) {
                        this.reset();
                        this.$rootScope.$digest();
                    }
                },
            );
        }
    }
    updateAddress(prediction: google.maps.places.AutocompletePrediction): void {
        this.placesService.getDetails(
            {
                placeId: prediction.place_id,
                sessionToken: this.sessionToken,
                fields: ['address_components', 'formatted_address', 'geometry'],
            },
            (result, status) => {
                if (status === 'OK') {
                    const address = {
                        ...this.parsePlace(result.address_components),
                        id: get('id', this.address),
                    };
                    this.update({ address, result });
                    this.street = address.street;
                    this.reset({ ignoreCache: true });
                    this.$rootScope.$digest();
                }
            },
        );
    }
    parsePlace(addressComponents: google.maps.GeocoderAddressComponent[]): IAddress {
        return reduce(
            (result: IAddress, value) => {
                switch (value.types[0]) {
                    case 'subpremise':
                        result.street += value.long_name + '/';
                        break;
                    case 'street_number':
                        result.street += value.long_name + ' ';
                        break;
                    case 'route':
                        result.street += value.long_name;
                        break;
                    case 'administrative_area_level_1':
                        result.state = value.short_name;
                        break;
                    case 'administrative_area_level_2':
                        result.region = value.long_name;
                        break;
                    case 'administrative_area_level_3':
                        result.metro_area = value.long_name;
                        break;
                    case 'country':
                        result.country = value.long_name;
                        break;
                    case 'postal_code':
                        result.postal_code = value.long_name;
                        break;
                    case 'locality':
                        result.city = value.long_name;
                        break;
                }
                return result;
            },
            {
                street: '',
                location: defaultTo('Home', get('location', this.address)),
                source: defaultTo('MPDX', get('source', this.address)),
            } as IAddress,
            addressComponents,
        );
    }
}

const AddressFinder = {
    controller: AddressFinderController,
    template: require('./addressFinder.html'),
    bindings: {
        address: '<',
        update: '&',
        editable: '<',
        autofocus: '<',
    },
};

export default angular
    .module('mpdx.common.addressFinder.component', [addressFinder])
    .component('addressFinder', AddressFinder).name;
