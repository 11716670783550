import 'angular-gettext';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../../common/api/api.service';

class ConfirmController {
    appName = process.env.APP_NAME || 'MPDX';
    saving: boolean;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private api: ApiService,
        private email: string,
    ) {
        this.saving = false;
    }
    sendInvite(): ng.IPromise<any> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{appName}} sent an invite to {{email}}', {
            email: this.email,
            appName: this.appName,
        });
        const errorMessage = this.gettextCatalog.getString(
            "{{appName}} couldn't send an invite (check to see if email address is valid)",
            { appName: this.appName },
        );
        return this.api
            .post({
                url: `account_lists/${this.api.account_list_id}/invites`,
                data: { recipient_email: this.email, invite_user_as: 'user' },
                type: 'account_list_invites',
                successMessage,
                errorMessage,
            })
            .then(() => {
                this.email = '';
                this.saving = false;
                this.$scope.$hide();
                return this.accounts.listInvites();
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
}

export default angular
    .module('mpdx.preferences.accounts.invites.confirm.controller', ['gettext', accounts, api])
    .controller('inviteConfirmController', ConfirmController).name;
