import 'angular-gettext';
import * as Upload from 'ng-file-upload';
import { get } from 'lodash/fp';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../common/api/api.service';
import users, { UsersService } from '../../../common/users/users.service';

export class PreferencesOrganizationService {
    data: any;
    state: string;
    constructor(
        private $log: ng.ILogService,
        private $window: ng.IWindowService,
        private Upload: ng.angularFileUpload.IUploadService,
        private alerts: AlertsService,
        private api: ApiService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private users: UsersService,
    ) {
        this.data = {};
        this.state = 'disabled';
    }
    save(successMessage, errorMessage) {
        return this.api.put('user/organization_accounts', this.data, successMessage, errorMessage).then((data) => {
            this.data.organization_accounts = data;
            return this.updateState();
        });
    }
    sync(id, successMessage, errorMessage): ng.IPromise<void> {
        return this.api.get({
            url: `user/organization_accounts/${id}/sync`,
            successMessage,
            errorMessage,
        });
    }
    disconnect(id, successMessage, errorMessage) {
        return this.api.delete({
            url: `user/organization_accounts/${id}`,
            type: 'organization_accounts',
            successMessage,
            errorMessage,
        });
    }
    updateState() {
        if (this.data.active) {
            if (this.data.valid) {
                this.state = 'enabled';
            } else {
                this.state = 'error';
            }
        } else {
            this.state = 'disabled';
        }
    }
    createAccount(username, password, organizationId) {
        const successMessage = this.gettextCatalog.getString('MPDX added your organization account');
        const errorMessage = this.gettextCatalog.getString('Invalid username or password.');
        let org: any = {
            organization: {
                id: organizationId,
            },
            person: {
                id: this.users.current.id,
            },
        };
        if (username && username.length > 0) {
            org.username = username;
        }
        if (password && password.length > 0) {
            org.password = password;
        }
        return this.api
            .post({
                url: 'user/organization_accounts',
                data: org,
                overridePromise: true,
            })
            .then(() => {
                this.alerts.addAlert(successMessage);
            })
            .catch((ex) => {
                this.alerts.addAlert(errorMessage, 'danger');
                throw ex;
            });
    }
    updateAccount(username, password, accountId, successMessage, errorMessage) {
        return this.api.put({
            url: `user/organization_accounts/${accountId}`,
            data: {
                id: accountId,
                username,
                password,
            },
            type: 'organization_accounts',
            successMessage,
            errorMessage,
        });
    }
    import(account) {
        return this.Upload.upload({
            method: 'POST',
            url: `${process.env.API_URL}/account_lists/${this.api.account_list_id}/imports/tnt_data_sync`,
            data: {
                data: {
                    type: 'imports',
                    attributes: {
                        file: account.file,
                    },
                    relationships: {
                        source_account: {
                            data: {
                                id: account.id,
                                type: 'organization_accounts',
                            },
                        },
                    },
                },
            },
        });
    }
    oAuth(organizationId, route = 'preferences/integrations?selectedTab=organization') {
        const redirectUrl = this.$window.encodeURIComponent(`${this.$window.location.origin}/${route}`);
        const token = this.$window.localStorage.getItem('token');
        const accountListId = this.api.account_list_id;
        return (
            `${process.env.OAUTH_URL}/donorhub?account_list_id=${accountListId}` +
            `&redirect_to=${redirectUrl}` +
            `&access_token=${token}` +
            `&organization_id=${organizationId}`
        );
    }
    ministryAccountRequired(selected): boolean {
        const apiClasses = ['Siebel', 'Remote::Import::OrganizationAccountService'];
        return get('api_class', selected) && apiClasses.indexOf(selected.api_class) !== -1;
    }
    loginRequired(selected): boolean {
        const apiClasses = ['DataServer', 'DataServerPtc', 'DataServerNavigators', 'DataServerStumo'];
        return (
            !this.oauthRequired(selected) && get('api_class', selected) && apiClasses.indexOf(selected.api_class) !== -1
        );
    }
    oauthRequired(selected): boolean {
        return get('oauth', selected);
    }
}

export default angular
    .module('mpdx.preferences.integrations.organization.service', [Upload, 'gettext', alerts, api, users])
    .service('preferencesOrganization', PreferencesOrganizationService).name;
