import 'angular-gettext';
import { assign, get } from 'lodash/fp';
import filters, { FiltersService } from '../../common/filters/filters.service';
import tasksTags, { TasksTagsService } from './tags/tags.service';

export class TasksFilterService {
    data: any;
    defaultParams: any;
    defaultParamsForGroup: any;
    defaultParamsWithGroup: any;
    params: any;
    selectedSave: string;
    wildcardSearch: string;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private filters: FiltersService,
        private tasksTags: TasksTagsService,
    ) {
        this.data = null;
        this.params = {};
        this.wildcardSearch = '';
        this.defaultParams = {};
        this.defaultParamsWithGroup = {};
        this.defaultParamsForGroup = {
            all: {
                starred: null,
                date_range: null,
            },
            today: {
                starred: null,
                completed: 'false',
                date_range: 'today',
            },
            overdue: {
                starred: null,
                completed: 'false',
                date_range: 'overdue',
            },
            upcoming: {
                starred: null,
                completed: 'false',
                date_range: 'upcoming',
            },
            noDueDate: {
                starred: null,
                completed: 'false',
                date_range: 'no_date',
            },
            contact: {
                starred: null,
                completed: null,
                date_range: null,
            },
        };
    }
    load(reset = false, group = 'all'): ng.IPromise<any> {
        this.data = reset ? null : this.data;
        return this.filters
            .load({
                data: this.data,
                defaultParams: this.defaultParams,
                params: this.params,
                url: 'tasks/filters',
            })
            .then(({ data, defaultParams, params }) => {
                // Filter out the whole tags filter type from the list of available task filters.
                this.data = data.filter((tag) => tag.id !== 28 && tag.name !== 'tags');
                this.defaultParams = defaultParams;
                this.params = params;
                this.assignDefaultParamsAndGroup(group);
            });
    }
    count(): number {
        return this.filters.count({
            defaultParams: this.defaultParamsWithGroup,
            params: this.params,
            tags: this.tasksTags,
        });
    }
    reset(stateParams: any = null): void {
        this.tasksTags.reset();
        [this.params, this.defaultParams, this.data, this.wildcardSearch, this.selectedSave] = this.filters.reset(
            this.defaultParams,
            stateParams,
            this.data,
        );
        this.assignDefaultParamsAndGroup('all');
        if (!get('completed', stateParams)) {
            this.params.completed = 'false';
        }
        this.change();
    }
    change(filter?: any): any {
        this.selectedSave = null;
        [filter, this.params] = this.filters.handleFilterChange(filter, this.params, this.defaultParams);
        this.$rootScope.$emit('tasksFilterChange');
        this.$log.debug('tasksFilter: params', this.params);
        this.$log.debug('tasksTags: selectedTags', this.tasksTags.selectedTags);
        this.$log.debug('tasksTags: rejectedTags', this.tasksTags.rejectedTags);
        return filter;
    }
    assignDefaultParamsAndGroup(group: string): void {
        const paramsForGroup = this.defaultParamsForGroup[group];
        this.defaultParamsWithGroup = assign(this.defaultParams, paramsForGroup);
        this.params = assign(this.params, paramsForGroup);
    }
    isResettable(): boolean {
        return this.filters.isResettable(
            { ...this.params, completed: undefined },
            { ...this.defaultParamsWithGroup, completed: undefined },
            this.tasksTags.isResettable(),
            this.wildcardSearch,
        );
    }
    buildFilterParams(accountListId?: string): any {
        return this.filters.buildFilterParams(
            this.defaultParams,
            this.params,
            this.wildcardSearch,
            this.tasksTags.selectedTags,
            this.tasksTags.rejectedTags,
            this.tasksTags.anyTags,
            accountListId,
        );
    }
    // Invert the selected options of a multiselect filter
    invertMultiselect(filter: any): void {
        [this.params, filter] = this.filters.invertMultiselect(this.params, filter);
        this.change();
    }
    removeFilter(filter: any): void {
        const reverseName = `reverse_${filter.name}`;
        this.params[filter.name] = this.defaultParams[filter.name];
        delete this.params[reverseName];
        filter.reverse = false;
        this.change();
    }
}

export default angular
    .module('mpdx.tasks.filter.service', ['gettext', filters, tasksTags])
    .service('tasksFilter', TasksFilterService).name;
