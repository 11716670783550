import 'angular-gettext';
import { concat, includes, isFunction, map, reduce } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import alerts, { AlertsService } from '../../common/alerts/alerts.service';
import appeals, { AppealsService } from '../../tools/appeals/appeals.service';
import contacts, { ContactsService } from '../contacts.service';
import mailchimp, { MailchimpService } from '../../preferences/integrations/mailchimp/mailchimp.service';
import modal, { ModalService } from '../../common/modal/modal.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../tasks/tasks.service';
import uiRouter from '@uirouter/angularjs';

export class BulkActionsController {
    data: any[];
    onHide: (data: { contacts: { id: string; status: string }[] }) => void;
    selectedIds: string[];
    watcher0: () => void;
    constructor(
        private $element: ng.IAugmentedJQuery,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $transclude: ng.ITranscludeFunction,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private appeals: AppealsService,
        private contacts: ContactsService,
        private mailchimp: MailchimpService,
        private modal: ModalService,
        private serverConstants: ServerConstantsService,
        private tasks: TasksService,
    ) {}
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.mailchimp.load();
        });
        angular.element(this.$element).children('.btn-group').append(this.$transclude());
    }
    $onDestroy(): void {
        this.watcher0();
    }
    allowed(): boolean {
        return this.selectedIds.length > 0;
    }
    openAppealAdd(): ng.IPromise<void> {
        const title = this.gettextCatalog.getString('Appeal');
        return this.modal.open({
            template: require('../../common/collectionSelector/modal/modal.html'),
            controller: 'collectionSelectionModalController',
            locals: {
                collectionSearch: (text) => this.appeals.search(text),
                itemName: title,
                searchText: '',
                select: (item) => this.onAppealSelect(item),
            },
        });
    }
    openAppealNew(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./appeal/new/new.html'),
            controller: 'contactsBulkActionsAppealNewController',
            locals: {
                selectedContactIds: this.selectedIds,
            },
        });
    }
    openContactEdit(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./contact/edit/edit.html'),
            controller: 'contactsBulkActionsContactEditController',
            locals: {
                selectedContacts: this.getSelectedContacts(),
            },
            resolve: {
                0: () =>
                    this.serverConstants.load(['assignable_language_hashes', 'bulk_update_option_hashes', 'locales']),
            },
        });
    }
    openContactExportCustom(): ng.IPromise<any> {
        return this.modal.open({
            template: require('./contact/export/custom/custom.html'),
            controller: 'contactsBulkActionsContactExportCustomController',
            locals: {
                selectedContactIds: this.selectedIds,
                filters: null,
            },
        });
    }
    openContactExportEmail(): ng.IPromise<void> {
        return this.modal.open({
            template: require('../../common/emailExport/email.html'),
            controller: 'exportEmailsController',
            locals: {
                contactIds: this.selectedIds,
            },
        });
    }
    openContactExportMailchimp(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./contact/export/mailchimp/mailchimp.html'),
            controller: 'contactsBulkActionsContactExportMailchimpController',
            locals: {
                selectedContactIds: this.selectedIds,
            },
        });
    }
    openContactExportMailchimpAllowed(): boolean {
        return this.mailchimp.data && this.mailchimp.data.lists_present;
    }
    openContactHide(): ng.IPromise<void> {
        const message = this.gettextCatalog.getString(
            'Are you sure you wish to hide the selected contacts? ' +
                'Hiding a contact in MPDX actually sets the contact status to "Never Ask".',
        );
        return this.modal.confirm(message).then(() => {
            const contacts = map((contact) => {
                return {
                    id: contact,
                    status: 'Never Ask',
                };
            }, this.selectedIds);
            return this.contacts.bulkSave(contacts).then(() => {
                if (isFunction(this.onHide)) {
                    this.onHide({ contacts });
                }
            });
        });
    }
    openContactMap(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./contact/map/map.html'),
            controller: 'contactsBulkActionsContactMapController',
            locals: {
                selectedContacts: this.getSelectedContacts(),
            },
        });
    }
    openContactMerge(): ng.IPromise<void> {
        const selectedLength = this.selectedIds.length;
        if (selectedLength < 2) {
            this.alerts.addAlert(
                this.gettextCatalog.getPlural(
                    2,
                    'You must select at least 1 contact to merge.',
                    'You must select at least {{$count}} contacts to merge.',
                    {},
                ),
                'danger',
            );
        } else if (selectedLength > 8) {
            this.alerts.addAlert(
                this.gettextCatalog.getPlural(
                    8,
                    'You can only merge up to 1 contact at a time.',
                    'You can only merge up to {{$count}} contacts at a time.',
                    {},
                ),
                'danger',
            );
        } else {
            return this.modal.open({
                template: require('./contact/merge/merge.html'),
                controller: 'contactsBulkActionsContactMergeController',
                locals: {
                    selectedContacts: this.getSelectedContacts(),
                },
            });
        }
    }
    openTagAdd(): ng.IPromise<any> {
        return this.modal.open({
            template: require('./tag/add/add.html'),
            controller: 'contactsBulkActionsTagAddController',
            locals: {
                selectedContacts: this.selectedIds,
            },
        });
    }
    openTagRemove(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./tag/remove/remove.html'),
            controller: 'contactsBulkActionsTagRemoveController',
            locals: {
                selectedContacts: this.getSelectedContacts(),
                currentListSize: this.data.length,
            },
        });
    }
    openTaskAdd(): ng.IPromise<void> {
        return this.tasks.addModal({ contactsList: this.selectedIds });
    }
    openTaskLog(): ng.IPromise<void> {
        return this.tasks.logModal(this.selectedIds);
    }
    private getSelectedContacts(): any[] {
        if (this.selectedIds.length > this.data.length) {
            return map((id) => ({ id }), this.selectedIds);
        }
        return reduce(
            (result, contact) => {
                const hasId = includes(contact.id, this.selectedIds);
                return hasId ? concat(result, contact) : result;
            },
            [],
            this.data,
        );
    }
    private onAppealSelect(appeal: any): ng.IPromise<void> {
        return this.appeals.bulkAddContacts(appeal.id, this.selectedIds).then(() => {
            this.$state.go('tools.appeals.show.asked', {
                appealId: appeal.id,
            });
        });
    }
}

const BulkActions = {
    controller: BulkActionsController,
    template: require('./bulkActions.html'),
    transclude: true,
    bindings: {
        selectedIds: '<',
        data: '<',
        onHide: '&',
        showAppealActions: '<',
    },
};

export default angular
    .module('mpdx.contacts.bulkActions.component', [
        'gettext',
        uiRouter,
        alerts,
        appeals,
        contacts,
        mailchimp,
        modal,
        serverConstants,
        tasks,
    ])
    .component('contactsBulkActions', BulkActions).name;
