import 'angular-gettext';
import { reject } from 'lodash/fp';
import preferencesOrganizations, { OrganizationsService } from '../organizations.service';
import users, { UsersService } from '../../../common/users/users.service';

class ShareController {
    appName = process.env.APP_NAME || 'MPDX';
    saving: boolean;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private preferencesOrganizations: OrganizationsService,
        private users: UsersService, // used in view
    ) {
        this.saving = false;
    }
    destroyInvite(id): ng.IPromise<void> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('removed the invite successfully');
        const errorMessage = this.gettextCatalog.getString("couldn't remove the invite");
        return this.preferencesOrganizations
            .destroyInvite(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.preferencesOrganizations.inviteList = reject({ id }, this.preferencesOrganizations.inviteList);
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    destroyAdmin(id): ng.IPromise<void> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('removed the admin successfully');
        const errorMessage = this.gettextCatalog.getString("couldn't remove the admin");
        return this.preferencesOrganizations
            .destroyAdmin(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.preferencesOrganizations.adminList = reject({ id }, this.preferencesOrganizations.adminList);
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
}

const Share = {
    controller: ShareController,
    template: require('./share.html'),
    bindings: {
        setup: '<',
    },
};

export default angular
    .module('mpdx.preferences.organizations.share', ['gettext', preferencesOrganizations, users])
    .component('shareOrganizationsPreferences', Share).name;
