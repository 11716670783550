import 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { concat, defaultTo, includes, isEmpty, map, pull, pullAllBy, reduce, union } from 'lodash/fp';
import { HotkeysProvider } from 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { StateParams, StateService } from '@uirouter/core';
import analysisFilter, { AnalysisFilterService } from './filter/filter.service';
import api, { ApiService } from '../../common/api/api.service';
import contacts, { ContactsService } from '../../contacts/contacts.service';
import contactsTags, { ContactsTagsService } from '../../contacts/sidebar/filter/tags/tags.service';
import designationAccounts, {
    DesignationAccountsService,
} from '../../common/designationAccounts/designationAccounts.service';
import pagination from '../../common/pagination/pagination';
import session, { SessionService } from '../../common/session/session.service';
import users, { UsersService } from '../../common/users/users.service';

export class AnalysisController {
    allSelected: boolean;
    data: any;
    listLoadCount: number;
    loading: boolean;
    meta: any;
    page: number;
    pageSize: string;
    pagination: pagination;
    sort: string;
    totalContactCount: number;
    selectedContactIds: string[];
    watcher: () => void;
    watcher2: () => void;
    watcher3: () => void;
    watcher4: () => void;
    watcher5: () => void;
    watcher6: () => void;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private $window: ng.IWindowService, // used in view
        private hotkeys: HotkeysProvider, // used in view
        private analysisFilter: AnalysisFilterService,
        private api: ApiService,
        private contacts: ContactsService,
        private contactsTags: ContactsTagsService,
        private designationAccounts: DesignationAccountsService,
        private session: SessionService, // used in view
        private users: UsersService,
    ) {
        this.allSelected = false;
        this.data = [];
        this.loading = false;
        this.meta = {};
        this.page = 0;
        this.pageSize = defaultTo('25', this.users.getCurrentOptionValue('page_size_analysis'));
        this.pagination = pagination;
        this.selectedContactIds = [];
        this.sort = 'name';
        this.totalContactCount = 0;
    }
    $onInit(): void {
        this.page = defaultTo(1, this.$stateParams.page);
        this.listLoadCount = 0;
        this.load(this.page);

        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.refreshFiltersAndTags().then(() => {
                this.pageChange();
            });
            this.clearSelectedContacts();
        });

        this.watcher2 = this.$rootScope.$on('analysisFilterChange', () => {
            this.clearSelectedContacts();
            this.pageChange();
        });

        this.watcher3 = this.$rootScope.$on('contactsTagsChange', () => {
            this.clearSelectedContacts();
            this.pageChange();
        });

        this.watcher4 = this.$rootScope.$on('contactTagsAdded', () => {
            this.clearSelectedContacts();
            this.load();
        });

        this.watcher5 = this.$rootScope.$on('contactTagDeleted', () => {
            this.clearSelectedContacts();
            this.load();
        });

        this.watcher6 = this.$rootScope.$on('designationAccountSelectorChanged', () => {
            this.clearSelectedContacts();
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher();
        this.watcher2();
        this.watcher3();
        this.watcher4();
        this.watcher5();
        this.watcher6();
    }
    load(page = 1): ng.IPromise<any> {
        let currentCount, params;
        this.listLoadCount++;
        currentCount = angular.copy(this.listLoadCount);
        params = this.analysisFilter.buildFilterParams();
        if (!isEmpty(this.designationAccounts.selected)) {
            params.designation_account_id = this.designationAccounts.selected;
        }

        this.loading = true;
        this.meta = {};
        this.data = null;
        this.page = page;
        return this.api
            .get({
                url: 'reports/partner_giving_analysis',
                data: {
                    filter: params,
                    page,
                    per_page: this.pageSize,
                    fields: {
                        contacts:
                            'donation_period_average,donation_period_count,donation_period_sum,' +
                            'last_donation_amount,last_donation_currency,last_donation_date,' +
                            'name,pledge_currency,total_donations',
                    },
                    sort: this.sort,
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('tpd page ' + data.meta.pagination.page, data);
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.meta = data.meta;
                if (data.length === 0) {
                    this.getTotalCount();
                    this.loading = false;
                    return;
                }
                this.data = data;
                this.loading = false;
                return this.data;
            });
    }
    getCompleteFilteredList(): ng.IPromise<any> {
        let params = this.analysisFilter.buildFilterParams();
        if (!isEmpty(this.designationAccounts.selected)) {
            params.designation_account_id = this.designationAccounts.selected;
        }
        return this.api.get({
            url: 'reports/partner_giving_analysis',
            data: {
                filter: params,
                fields: {
                    contacts: '',
                },
                per_page: 25000,
            },
            overrideGetAsPost: true,
        });
    }
    getSelectedContacts(): any[] {
        if (this.selectedContactIds.length > this.data.length) {
            return map((id) => ({ id }), this.selectedContactIds);
        }
        return reduce(
            (result, contact) => {
                const hasId = includes(contact.id, this.selectedContactIds);
                return hasId ? concat(result, contact) : result;
            },
            [],
            this.data,
        );
    }
    getTotalCount(): ng.IPromise<void> {
        return this.contacts.getTotalCount().then((data: number) => {
            this.totalContactCount = data;
        });
    }
    changeSort(key: string): void {
        this.sort = this.sort === key ? `-${key}` : key;
        this.load();
    }
    pageChange(page = 1): void {
        if (this.$stateParams.page === 1 && page === 1) {
            this.load();
        }
        this.$state.go('reports.analysis', { page }, { reload: false });
    }
    pageSizeChange(size): void {
        this.pageSize = size;
        this.load(1);
    }
    refreshFiltersAndTags(): ng.IPromise<any> {
        return this.$q.all([this.analysisFilter.load(true), this.contactsTags.load()]);
    }
    selectAllContacts(all = true): ng.IPromise<any> {
        if (all) {
            this.allSelected = true; // for reactive visuals
            return this.getCompleteFilteredList()
                .then((data) => {
                    this.allSelected = false;
                    this.selectedContactIds = map('id', data);
                })
                .catch(() => {
                    this.allSelected = false;
                });
        } else {
            this.selectedContactIds = map('id', this.data);
        }
    }
    onContactsHide(contacts): void {
        this.data = pullAllBy('id', contacts, this.data);
        this.clearSelectedContacts();
    }
    clearSelectedContacts(): void {
        this.selectedContactIds = [];
    }
    isSelected(contactId: string): boolean {
        return includes(contactId, this.selectedContactIds);
    }
    selectContact(contactId: string): void {
        if (includes(contactId, this.selectedContactIds)) {
            this.selectedContactIds = pull(contactId, this.selectedContactIds);
        } else {
            this.selectedContactIds = union(this.selectedContactIds, [contactId]);
        }
    }
}

const Analysis: ng.IComponentOptions = {
    template: require('./analysis.html'),
    controller: AnalysisController,
};

export default angular
    .module('mpdx.reports.analysis.component', [
        'cfp.hotkeys',
        api,
        analysisFilter,
        contacts,
        contactsTags,
        designationAccounts,
        session,
        users,
    ])
    .component('analysis', Analysis).name;
