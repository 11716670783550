import { v1 as uuidv1 } from 'uuid';
import users, { UsersService } from '../../common/users/users.service';

export interface IFlowsConfig {
    color: string;
    id: string;
    name: string;
    statuses: string[];
}
export class FlowsService {
    draggable: any;
    config: IFlowsConfig[];
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private users: UsersService,
    ) {}
    setDraggable(draggable): void {
        this.draggable = draggable;
    }
    getConfig(): any[] {
        if (!this.config && this.users.currentOptions.flows) {
            this.config = JSON.parse(this.users.currentOptions.flows.value);
        } else if (!this.config) {
            this.resetConfig();
        }
        return this.config;
    }
    saveConfig(config: any[]): ng.IPromise<any> {
        this.config = config;
        return this.users.saveOption('flows', JSON.stringify(config));
    }
    resetConfig(): ng.IPromise<any> {
        this.config = [
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Waiting'),
                statuses: ['Ask in Future'],
                color: 'color-danger',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('To Do'),
                statuses: ['Never Contacted', 'Cultivate Relationship', 'Contact for Appointment'],
                color: 'color-text',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Appointment Scheduled'),
                statuses: ['Appointment Scheduled'],
                color: 'color-info',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Callback'),
                statuses: ['Call for Decision'],
                color: 'color-warning',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Partners'),
                statuses: ['Partner - Financial', 'Partner - Special', 'Partner - Pray'],
                color: 'color-success',
            },
        ];
        return this.users.saveOption('flows', JSON.stringify(this.config));
    }
}

export default angular.module('mpdx.contacts.flows.service', ['gettext', users]).service('contactsFlows', FlowsService)
    .name;
