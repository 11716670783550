import contactsTags, { ContactsTagsService } from './tags/tags.service';
import filters, { FiltersService } from '../../../common/filters/filters.service';

export class ContactFilterService {
    data: any;
    defaultParams: any;
    params: any;
    selectedSave: string;
    wildcardSearch: string;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private contactsTags: ContactsTagsService,
        private filters: FiltersService,
    ) {
        this.data = null;
        this.defaultParams = {};
        this.params = {};
        this.wildcardSearch = '';
    }
    load(reset = false): ng.IPromise<any> {
        let payload = {
            data: null,
            defaultParams: null,
            params: null,
            url: 'contacts/filters',
        };
        if (!reset) {
            payload.data = this.data;
            payload.defaultParams = this.defaultParams;
            payload.params = this.params;
        }
        return this.filters.load(payload).then(({ data, defaultParams, params }) => {
            // Filter out the whole tags filter type from the list of available contact filters.
            this.data = data.filter((tag) => tag.id !== 44 && tag.name !== 'tags');
            this.defaultParams = defaultParams;
            this.params = params;
        });
    }
    count(): number {
        return this.filters.count({
            defaultParams: this.defaultParams,
            params: this.params,
            tags: this.contactsTags,
        });
    }
    reset(stateParams: any = null): void {
        this.$rootScope.$emit('contactSearchReset');
        this.contactsTags.reset();
        [this.params, this.defaultParams, this.data, this.wildcardSearch, this.selectedSave] = this.filters.reset(
            this.defaultParams,
            stateParams,
            this.data,
        );
        this.change();
    }
    change(filter?: any): any {
        this.selectedSave = null;
        [filter, this.params] = this.filters.handleFilterChange(filter, this.params, this.defaultParams);
        this.$rootScope.$emit('contactsFilterChange');
        this.$log.debug('contactFilter: params', this.params);
        this.$log.debug('contactsTags: selectedTags', this.contactsTags.selectedTags);
        this.$log.debug('contactsTags: rejectedTags', this.contactsTags.rejectedTags);
        return filter;
    }
    isResettable(): boolean {
        return this.filters.isResettable(
            this.params,
            this.defaultParams,
            this.contactsTags.isResettable(),
            this.wildcardSearch,
        );
    }
    buildFilterParams(accountListId?: string): any {
        return this.filters.buildFilterParams(
            this.defaultParams,
            this.params,
            this.wildcardSearch,
            this.contactsTags.selectedTags,
            this.contactsTags.rejectedTags,
            this.contactsTags.anyTags,
            accountListId,
        );
    }
    // Invert the selected options of a multiselect filter
    invertMultiselect(filter: any): void {
        [this.params, filter] = this.filters.invertMultiselect(this.params, filter);
        this.change();
    }
    removeFilter(filter: any): void {
        const reverseName = `reverse_${filter.name}`;
        this.params[filter.name] = this.defaultParams[filter.name];
        delete this.params[reverseName];
        filter.reverse = false;
        this.change();
    }
}

export default angular
    .module('mpdx.contacts.sidebar.filter.service', [contactsTags, filters])
    .service('contactFilter', ContactFilterService).name;
