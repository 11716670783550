import { compact, find, get, isArray, isString, map } from 'lodash/fp';
import reduceObject from '../../../fp/reduceObject';

class FilterController {
    filter: any;
    filterParams: any;
    filters: string[];
    $onChanges() {
        if (this.filter.type === 'daterange') {
            this.filters = [this.filterParams];
        } else if (isArray(this.filterParams)) {
            this.filters = compact(map((value: string) => this.getOption(value), this.filterParams));
        } else if (isString(this.filterParams)) {
            this.filters = [this.getOption(this.filterParams)];
        } else {
            this.filters = reduceObject(
                (result, value, key) => {
                    let option = find({ id: key }, get('options', this.filter));
                    if (get('placeholder', option) !== value) {
                        result.push(get('name', option));
                    }
                    return result;
                },
                [],
                this.filterParams,
            );
        }
    }
    private getOption(id: string): string {
        return get('name', find({ id }, get('options', this.filter)));
    }
}

const filter: ng.IComponentOptions = {
    template: require('./filter.html'),
    controller: FilterController,
    bindings: {
        filter: '<',
        filterParams: '<',
        invert: '&',
        remove: '&',
    },
};

export default angular.module('mpdx.common.filter.header.filter.component', []).component('filtersHeaderFilter', filter)
    .name;
