import 'angular-gettext';
import * as moment from 'moment';
import api, { ApiService } from '../../../common/api/api.service';
import help, { HelpService } from '../../../common/help/help.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';

class NeedsController {
    accountListId: string;
    appealId: string;
    inCoaching: boolean;
    meta: any;
    pledges: any[];
    loading: boolean;
    showHelpButton = true;
    constructor(
        private $log: ng.ILogService,
        private api: ApiService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
        private serverConstants: ServerConstantsService, // used in view
    ) {
        if (!this.help.variables().HS_COACHING_OUTSTANDING_SPECIAL_NEEDS?.length) {
            this.showHelpButton = false;
        }
    }
    $onChanges() {
        this.loading = true;
        this.meta = {};
        this.pledges = [];
        this.load();
    }
    private load(page = 1): ng.IPromise<void> {
        return this.api
            .get(`account_lists/${this.accountListId}/pledges`, {
                include: 'contact',
                page,
                per_page: 20,
                fields: {
                    contacts: 'name,pledge_amount,pledge_currency,pledge_frequency',
                },
                filter: {
                    appeal_id: this.appealId,
                    status: 'not_received',
                },
                sort: 'contact.name',
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug(`account_lists/${this.accountListId}/pledges`, data);
                this.pledges = data;
                this.meta = data.meta;
                this.loading = false;
            });
    }
    fromNow(date) {
        return moment(date).fromNow();
    }
    showHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_COACHING_OUTSTANDING_SPECIAL_NEEDS));
    }
}

const Needs = {
    template: require('./needs.html'),
    controller: NeedsController,
    bindings: {
        accountListId: '<',
        appealId: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.outstandingSpecialNeeds.component', ['gettext', api, help, serverConstants])
    .component('outstandingSpecialNeeds', Needs).name;
