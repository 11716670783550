import { compact, defaultTo, map } from 'lodash/fp';
import { StateParams, StateService } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import appeals, { AppealsService } from '../appeals.service';
import appealsShow, { AppealsShowService } from './show.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import tasks, { TasksService } from '../../../tasks/tasks.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../../../common/users/users.service';

interface ICustomRootScope extends ng.IRootScopeService {
    pageTitle: string;
}

export class ShowController {
    pageSize: number;
    tour: string;
    watcher0: () => void;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ICustomRootScope,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettext: ng.gettext.gettextFunction,
        private api: ApiService,
        private appeals: AppealsService, // used in view
        private appealsShow: AppealsShowService,
        private contacts: ContactsService, // used in view
        private tasks: TasksService, // used in view
        private users: UsersService,
    ) {
        this.appealsShow.deselectAll();
        this.pageSize = defaultTo(25, this.users.getCurrentOptionValue('page_size_appeal'));
    }
    $onInit(): void {
        this.tour = this.$stateParams.tour;
        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.$state.go('tools.appeals');
        });
    }
    $onDestroy(): void {
        this.watcher0();
    }
    changeToFlowsView(): void {
        this.$state.go('tools.appeals.flows', {
            appealId: this.$stateParams.appealId,
        });
        this.users.saveOption('appeal_view', 'flows');
    }
    next(): ng.IPromise<any> | void {
        if (this.tour === 'start') {
            this.$state.go('tools.appeals.show.excluded');
            this.tour = 'review_excluded';
        } else if (this.tour === 'review_excluded') {
            this.$state.go('tools.appeals.show.asked');
            this.tour = 'review_asked';
        } else if (this.tour === 'review_asked') {
            this.$state.go('tools.appeals.show.asked');
            this.tour = 'export_contacts';
            this.selectAllNotGiven();
        } else if (this.tour === 'export_contacts') {
            return this.appealsShow.exportToCSV().then(() => {
                this.appealsShow.deselectAll();
                this.$state.go('tools.appeals.show.asked');
                this.tour = 'finish';
            });
        } else {
            this.tour = null;
        }
    }
    hideTour(): void {
        this.tour = null;
    }
    selectAll(): void {
        if (this.$state.is('tools.appeals.show.given')) {
            this.selectAllPledges('processed');
        } else if (this.$state.is('tools.appeals.show.received')) {
            this.selectAllPledges('received_not_processed');
        } else if (this.$state.is('tools.appeals.show.committed')) {
            this.selectAllPledges('not_received');
        } else if (this.$state.is('tools.appeals.show.asked')) {
            this.selectAllNotGiven();
        }
    }
    selectAllPledges(status: string): ng.IPromise<void> {
        return this.api
            .get(`account_lists/${this.api.account_list_id}/pledges`, {
                include: 'contact',
                per_page: 10000,
                fields: {
                    pledges: 'contact',
                    contacts: '',
                },
                filter: {
                    appeal_id: this.$stateParams.appealId,
                    status,
                },
            })
            .then((appealContacts) => {
                const contactIds = compact(
                    map((appealContact) => {
                        if (appealContact.contact) {
                            return appealContact.contact.id;
                        }
                    }, appealContacts),
                );
                /* istanbul ignore next */
                this.$log.debug(`contact ids for ${status}`, contactIds);
                this.appealsShow.selectedContactIds = contactIds;
            });
    }
    selectAllNotGiven(): ng.IPromise<void> {
        return this.api
            .get(`appeals/${this.$stateParams.appealId}/appeal_contacts`, {
                per_page: 10000,
                include: 'contact',
                filter: {
                    pledged_to_appeal: false,
                },
                fields: {
                    appeal_contacts: 'contact',
                    contact: '',
                },
            })
            .then((appealContacts) => {
                const contactIds = compact(
                    map((appealContact) => {
                        if (appealContact.contact) {
                            return appealContact.contact.id;
                        }
                    }, appealContacts),
                );
                /* istanbul ignore next */
                this.$log.debug('select all contacts not given', contactIds);
                this.appealsShow.selectedContactIds = contactIds;
            });
    }
}

const Show: ng.IComponentOptions = {
    controller: ShowController,
    template: require('./show.html'),
};

export default angular
    .module('tools.mpdx.appeals.show', [uiRouter, api, appeals, appealsShow, contacts, tasks, users])
    .component('appealsShow', Show).name;
