import 'angular-gettext';
import 'ngmap';
import { concat, findIndex, get, reject } from 'lodash/fp';
import { IAddress, IContact } from '../../../../../common/types';
import contacts, { ContactsService } from '../../../../contacts.service';
import createPatch from '../../../../../common/fp/createPatch';
import serverConstants, { ServerConstantsService } from '../../../../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../../../../common/users/users.service';

export class AddressModalController {
    address: IAddress;
    addressInitialState: any;
    contact: IContact;
    editable: boolean;
    map: google.maps.Map;
    modalTitle: string;
    mouseOver: boolean;
    position: string;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private NgMap: angular.map.INgMap,
        private contacts: ContactsService,
        private serverConstants: ServerConstantsService, // used in view
        private users: UsersService,
        contact: IContact,
        address: any,
    ) {
        this.contact = contact;
        this.address = angular.copy(address);
        this.mouseOver = false;
        this.addressInitialState = angular.copy(address);
        this.modalTitle = this.setTitle(this.address, this.contact);
        if (this.address) {
            this.editable =
                !this.address.remote_id && ['MPDX', 'manual', 'TntImport'].indexOf(this.address.source) > -1;
        } else {
            this.address = { street: '', location: 'Home', source: 'MPDX' } as IAddress;
        }
        this.loadMap();
    }
    loadMap(): ng.IPromise<void> {
        return this.NgMap.getMap().then((map) => {
            this.map = map;
            this.refreshMap();
        });
    }
    setTitle(address?: any, contact?: any): string {
        return get('id', address)
            ? contact
                ? this.gettextCatalog.getString('Edit Address')
                : this.gettextCatalog.getString('Address')
            : this.gettextCatalog.getString('Add Address');
    }
    save(): ng.IPromise<void> {
        if (angular.isDefined(this.address.id)) {
            const addressIndex = findIndex({ id: this.address.id }, this.contact.addresses);
            this.contact.addresses[addressIndex] = angular.copy(this.address);
            const patch = createPatch(this.addressInitialState, this.address);
            return this.contacts.saveAddress(this.contact.id, patch).then(() => {
                this.$scope.$hide();
            });
        } else {
            return this.contacts.addAddress(this.contact.id, this.address).then((data) => {
                this.contact.addresses = concat(this.contact.addresses, data);
                this.$scope.$hide();
            });
        }
    }
    reqUpdateEmailBodyRequest(): string {
        if (this.address.source === 'Siebel') {
            const donorAccount = this.address.source_donor_account;
            const donorName = donorAccount
                ? `${this.contact.name} (ministry partner #${donorAccount.account_number})`
                : this.contact.name;
            const previousAddress = this.address.street
                ? `\nThey were previously located at:\n${this.address.street}\n${this.address.city}, ` +
                  `${this.address.state} ${this.address.postal_code}\n`
                : ' ';
            const message =
                `Dear Donation Services,\n\nOne of my ministry partners, ${donorName} ` +
                `has a new current address.\n${previousAddress}\nPlease update their address to:\n` +
                'REPLACE WITH NEW STREET\nREPLACE WITH NEW CITY, STATE, ZIP\n\nThanks,\n\n' +
                `${this.users.current.first_name}`;

            return encodeURIComponent(message);
        }

        return '';
    }
    update(address: IAddress, result: google.maps.places.PlaceResult, street: string = null): void {
        if (address && result) {
            this.address = address;
            this.refreshMap(result);
        }
        if (street) {
            this.address.street = street;
        }
    }
    refreshMap(result: google.maps.places.PlaceResult = null): void {
        if (!result && !this.address.geo) {
            this.position = null;
            return;
        }
        if (result) {
            this.position = `${result.geometry.location.lat()},${result.geometry.location.lng()}`;
            this.map.setCenter(result.geometry.location);
        } else {
            const geo = this.address.geo.split(',');
            this.position = this.address.geo;
            this.map.setCenter({ lat: parseFloat(geo[0]), lng: parseFloat(geo[1]) });
        }
        this.$window.google.maps.event.trigger(this.map, 'resize');
    }
    delete(): ng.IPromise<void> {
        return this.contacts.deleteAddress(this.contact.id, this.address.id).then(() => {
            this.contact.addresses = reject({ id: this.address.id }, this.contact.addresses);
            this.$scope.$hide();
        });
    }
}

export default angular
    .module('mpdx.contacts.show.addresses.address.modal.controller', [
        'gettext',
        'ngMap',
        contacts,
        serverConstants,
        users,
    ])
    .controller('addressModalController', AddressModalController).name;
