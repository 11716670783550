import { StateParams } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import uiRouter from '@uirouter/angularjs';

export class ReportController {
    data: any;
    meta: any;
    loading: boolean;
    constructor(
        private $stateParams: StateParams,
        private api: ApiService,
    ) {}
    $onInit(): void {
        this.load();
    }
    load(): ng.IPromise<any> {
        this.loading = true;
        return this.api
            .get('reports/entry_histories', {
                include:
                    'credit_by_categories,debit_by_categories,credit_by_categories.category,' +
                    'debit_by_categories.category',
                fields: {
                    reports_entry_histories_periods:
                        'closing_balance,opening_balance,start_date,end_date,' +
                        'credits,debits,difference,credit_by_categories,' +
                        'debit_by_categories',
                    financial_account_entry_by_categories: 'amount,category',
                    financial_account_entry_categories: 'name,code',
                },
                filter: {
                    account_list_id: this.api.account_list_id,
                    financial_account_id: this.$stateParams.financialAccountId,
                },
            })
            .then((data) => {
                this.loading = false;
                this.data = data;
                this.meta = data.meta;
            });
    }
}

const Report = {
    controller: ReportController,
    template: require('./report.html'),
};

export default angular
    .module('mpdx.reports.financialAccounts.report.component', [uiRouter, api])
    .component('financialAccountsReport', Report).name;
