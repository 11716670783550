import api, { ApiService } from '../../common/api/api.service';
import modal, { ModalService } from '../../common/modal/modal.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../tasks/tasks.service';

export class TasksController {
    data: any[];
    meta: { pagination: { total_count: number } };
    loading: boolean;
    watcher: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
        private modal: ModalService,
        private serverConstants: ServerConstantsService,
        private tasks: TasksService,
    ) {}
    $onInit(): void {
        this.load();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        return this.api
            .get({
                url: 'tasks',

                data: {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        start_at: this.startAt(),
                        completed: false,
                        activity_type: 'Prayer Request',
                        reverse_activity_type: true,
                    },
                    per_page: 3,
                    include: 'activity_contacts,activity_contacts.contact,user',
                    fields: {
                        activity_contacts: 'contact',
                        contact: 'name',
                        tasks:
                            'activity_contacts,activity_type,completed,completed_at,no_date,contacts,starred,start_at,' +
                            'subject,tag_list,comments_count,location,result,next_action,notification_type,' +
                            'notification_time_before,notification_time_unit,subject_hidden,user',
                        users: 'first_name,last_name',
                    },
                },
            })
            .then((data) => {
                this.loading = false;
                this.data = data;
                this.meta = data.meta;
            });
    }
    total(): number {
        return this.meta?.pagination?.total_count || 0;
    }
    startAt(): string {
        const date = new Date();
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        const endOfWeekDate = new Date(date.setDate(date.getDate() - date.getDay() + 6) - timezoneOffset);
        return `${new Date(0).toISOString().substring(0, 10)}..${endOfWeekDate.toISOString().substring(0, 10)}`;
    }
    edit(task): ng.IPromise<any> {
        return this.modal
            .open({
                template: require('../../tasks/modals/edit/edit.html'),
                controller: 'editTaskController',
                resolve: {
                    task: () => this.tasks.load(task.id),
                    0: () => this.serverConstants.load(['activity_hashes', 'next_actions', 'results']),
                },
            })
            .then(() => {
                this.load();
            });
    }
    complete(task): ng.IPromise<void> {
        return this.modal
            .open({
                template: require('../../tasks/modals/complete/complete.html'),
                controller: 'completeTaskController',
                resolve: {
                    task: () => this.tasks.load(task.id),
                    0: () => this.serverConstants.load(['next_actions', 'results', 'status_hashes']),
                },
            })
            .then(() => {
                this.load();
            });
    }
}

const Tasks = {
    template: require('./tasks.html'),
    controller: TasksController,
};

export default angular.module('mpdx.home.tasks', [api, modal, serverConstants, tasks]).component('homeTasks', Tasks)
    .name;
