import { assign, concat, defaultTo, get, map, reduce, replace, toString } from 'lodash/fp';
import api, { ApiService } from '../../common/api/api.service';
import users, { UsersService } from '../../common/users/users.service';

export class ListController {
    data: any[];
    loading: boolean;
    meta: any;
    listLoadCount: number;
    pageSize: string;
    watcher0: () => void;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
        private users: UsersService,
    ) {
        this.data = [];
        this.loading = false;
        this.meta = {};
        this.listLoadCount = 0;
        this.pageSize = defaultTo('25', this.users.getCurrentOptionValue('page_size_coaches'));
    }
    $onInit() {
        this.load();

        this.watcher0 = this.$rootScope.$on('accountListCoachDeleted', (_event, id) => {
            this.data = reduce(
                (result, contact) => (contact.id === id ? result : concat(result, contact)),
                [],
                this.data,
            );
        });
    }
    $onDestroy() {
        this.watcher0();
    }
    load(page = 1): ng.IPromise<any> {
        this.loading = true;
        this.reset();
        let currentCount = angular.copy(this.listLoadCount);
        return this.api
            .get({
                url: 'user/account_list_coaches',
                data: {
                    page,
                    per_page: this.pageSize,
                    include: 'account_list,account_list.users,account_list.primary_appeal',
                    fields: {
                        users: 'first_name,last_name,avatar',
                        appeals:
                            'amount,pledges_amount_not_received_not_processed,pledges_amount_received_not_processed,' +
                            'pledges_amount_processed',
                    },
                    sort: 'account_list.name',
                },
                type: 'account_list_coaches',
            })
            .then((data: any) => {
                const returnable = this.adjustValues(data);
                this.setData(returnable, data.meta, currentCount);
                this.loading = false;
                /* istanbul ignore next */
                this.$log.debug(`coaching account lists page ${data.meta.pagination.page}`, data);
            });
    }
    pageSizeChange(size): void {
        this.pageSize = size;
        this.load(1);
    }
    private adjustValues(data: any[]) {
        return map(
            (item) =>
                assign(item, {
                    account_list: assign(item.account_list, {
                        balance: this.cleanBalanceValue(item),
                    }),
                }),
            data,
        );
    }
    private cleanBalanceValue(item: any) {
        const balanceVal = get('balance', item.account_list);
        const balanceStr = toString(defaultTo('0', balanceVal));
        return replace(/[^\d.-]/g, '', balanceStr);
    }
    reset() {
        this.meta = {};
        this.data = [];
        this.listLoadCount++;
    }
    setData(data, meta, currentCount) {
        // ensures the last request becomes the data, not the latest response
        if (currentCount === this.listLoadCount) {
            this.data = data;
            this.meta = meta;
        }
    }
}

const List = {
    controller: ListController,
    template: require('./list.html'),
};

export default angular.module('mpdx.coaches.list.component', [api, users]).component('coachesList', List).name;
