import { get } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import analytics, { AnalyticsService } from '../analytics/analytics.service';
import api, { ApiService } from '../api/api.service';
import appeals, { AppealsService } from '../../tools/appeals/appeals.service';
import modal, { ModalService } from '../modal/modal.service';
import session, { SessionService } from '../../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

export interface IAnnouncement {
    id: string;
    display_method: string;
}

export interface IAnnouncementAction {
    id: string;
    action: string;
    args: string;
}

export class AnnouncementsService {
    constructor(
        private $state: StateService,
        private $q: ng.IQService,
        private analytics: AnalyticsService,
        private api: ApiService,
        private appeals: AppealsService,
        private modal: ModalService,
        private session: SessionService,
    ) {}
    load(): ng.IPromise<IAnnouncement> {
        return this.api
            .get({
                url: 'announcements',
                data: {
                    page: 1,
                    per_page: 1,
                    include: 'actions',
                    filter: {
                        language_code: this.api.language.toLowerCase(),
                    },
                },
                type: 'announcements',
            })
            .then((data: IAnnouncement) => {
                if (this.session.navImpersonation) {
                    return;
                }
                const announcement = get('[0]', data);
                if (get('display_method', announcement) === 'modal') {
                    this.openModal(announcement);
                } else {
                    return announcement;
                }
            });
    }
    perform(announcement: IAnnouncement, action: IAnnouncementAction): ng.IPromise<any> {
        switch (get('action', action)) {
            case 'go':
                if (action.args) {
                    this.$state.go(action.args);
                }
                break;
            case 'appeal_create':
                this.appeals.oneClickAppeal();
                break;
            case 'track': {
                const userRatingMatch = action.args.match(/^aa-(user-rating)-(\d)$/);
                if (userRatingMatch) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_adobeEventName, event, rating] = userRatingMatch;
                    this.analytics.dispatch(event, { rating });
                } else {
                    this.analytics.dispatch(action.args);
                }
            }
        }
        return this.acknowledge(get('id', announcement), get('id', action));
    }
    acknowledge(announcementId: string, actionId: string): ng.IPromise<any> {
        if (!announcementId) {
            return;
        }
        let data: { announcement: { id: string }; action?: { id: string } } = { announcement: { id: announcementId } };
        if (actionId) {
            data.action = { id: actionId };
        }
        return this.api.post({
            url: 'user/announcements',
            data,
            type: 'user_announcements',
        });
    }
    openModal(announcement: IAnnouncement): ng.IPromise<any> {
        return this.modal.open({
            template: require('./modal/modal.html'),
            controller: 'announcementsModalController',
            locals: {
                announcement,
            },
            backdrop: 'static',
        });
    }
}

export default angular
    .module('mpdx.common.announcements.service', [uiRouter, analytics, api, appeals, modal, session])
    .service('announcements', AnnouncementsService).name;
