import 'angular-gettext';
import { filter, map, reject } from 'lodash/fp';
import api, { ApiCollection, ApiPagination, ApiService } from '../../../common/api/api.service';
import joinComma from '../../../common/fp/joinComma';
import modal, { ModalService } from '../../../common/modal/modal.service';
import preferencesOrganizations, { OrganizationsService } from '../organizations.service';

export class AccountListsController {
    data: any[];
    meta: ApiPagination;
    listLoadCount: number;
    loading: boolean;
    organizationIds: string[];
    wildcardSearch: string;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private modal: ModalService,
        private preferencesOrganizations: OrganizationsService, // used in view
    ) {
        this.listLoadCount = 0;
        this.loading = false;
        this.organizationIds = [];
        this.wildcardSearch = '';
    }
    $onInit(): void {
        this.load();
    }
    load(page = 1): ng.IPromise<ApiCollection<any>> {
        this.loading = true;

        let currentCount;
        this.listLoadCount++;
        currentCount = angular.copy(this.listLoadCount);
        return this.api
            .get({
                url: 'organizations/account_lists',
                data: {
                    include:
                        'account_list_users,account_list_coaches,account_list_users.user_email_addresses,' +
                        'account_list_coaches.coach_email_addresses,designation_accounts,' +
                        'designation_accounts.organization,account_list_invites,' +
                        'account_list_invites.invited_by_user',
                    page,
                    filter: {
                        organization_id: joinComma(this.organizationIds),
                        wildcard_search: this.wildcardSearch,
                    },
                    fields: {
                        account_lists:
                            'name,account_list_coaches,account_list_users,' +
                            'account_list_invites,designation_accounts',
                        account_list_coaches: 'coach_first_name,coach_last_name,coach_email_addresses',
                        account_list_users: 'user_first_name,user_last_name,user_email_addresses,allow_deletion',
                        email_addresses: 'email,primary',
                        designation_accounts: 'display_name,organization',
                        organizations: 'name',
                        account_list_invites: 'recipient_email,invite_user_as,invited_by_user',
                        users: 'first_name,last_name',
                    },
                },
                method: 'POST',
                overrideGetAsPost: true,
            })
            .then((data: ApiCollection<any>) => {
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.loading = false;
                this.data = map((accountList) => {
                    accountList.account_list_coach_invites = filter(
                        { invite_user_as: 'coach' },
                        accountList.account_list_invites,
                    );
                    accountList.account_list_user_invites = filter(
                        { invite_user_as: 'user' },
                        accountList.account_list_invites,
                    );
                    delete accountList.account_list_invites;
                    return accountList;
                }, data);
                this.meta = data.meta;
                return data;
            });
    }
    deleteUser(accountList, user): ng.IPromise<any> {
        const msg = this.gettextCatalog.getString(
            'Are you sure you want to remove {{user}} as a user from {{accountList}}?',
            { accountList: accountList.name, user: user.first_name },
        );
        return this.modal.confirm(msg).then(() => {
            return this.api
                .delete({
                    url: `organizations/account_lists/${accountList.id}/account_list_users/${user.id}`,
                    type: 'account_list_users',
                })
                .then(() => {
                    this.data = map((mappedAccountList) => {
                        if (mappedAccountList.id === accountList.id) {
                            mappedAccountList.account_list_users = reject(
                                { id: user.id },
                                mappedAccountList.account_list_users,
                            );
                        }
                        return mappedAccountList;
                    }, this.data);
                });
        });
    }
    deleteCoach(accountList, coach): ng.IPromise<any> {
        const msg = this.gettextCatalog.getString(
            'Are you sure you want to remove {{coach}} as a coach from {{accountList}}?',
            { accountList: accountList.name, coach: coach.first_name },
        );
        return this.modal.confirm(msg).then(() => {
            return this.api
                .delete({
                    url: `organizations/account_lists/${accountList.id}/account_list_coaches/${coach.id}`,
                    type: 'account_list_coaches',
                })
                .then(() => {
                    this.data = map((mappedAccountList) => {
                        if (mappedAccountList.id === accountList.id) {
                            mappedAccountList.account_list_coaches = reject(
                                { id: coach.id },
                                mappedAccountList.account_list_coaches,
                            );
                        }
                        return mappedAccountList;
                    }, this.data);
                });
        });
    }
    deleteInvite(accountList, invite): ng.IPromise<any> {
        const msg = this.gettextCatalog.getString(
            'Are you sure you want to remove the invite for {{email}} from {{accountList}}?',
            { accountList: accountList.name, email: invite.recipient_email },
        );
        return this.modal.confirm(msg).then(() => {
            return this.api
                .delete({
                    url: `organizations/account_lists/${accountList.id}/invites/${invite.id}`,
                    type: 'account_list_invites',
                })
                .then(() => {
                    this.data = map((mappedAccountList) => {
                        if (mappedAccountList.id === accountList.id) {
                            mappedAccountList.account_list_coach_invites = reject(
                                { id: invite.id },
                                mappedAccountList.account_list_coach_invites,
                            );
                            mappedAccountList.account_list_user_invites = reject(
                                { id: invite.id },
                                mappedAccountList.account_list_user_invites,
                            );
                        }
                        return mappedAccountList;
                    }, this.data);
                });
        });
    }
    resetAll(): void {
        this.organizationIds = [];
        this.wildcardSearch = '';
        this.load();
    }
    resetOrganizationIds(): void {
        this.organizationIds = [];
        this.load();
    }
}

const AccountLists = {
    template: require('./accountLists.html'),
    controller: AccountListsController,
};

export default angular
    .module('mpdx.preferences.organizations.accountLists.component', ['gettext', api, modal, preferencesOrganizations])
    .component('preferencesOrganizationsAccountLists', AccountLists).name;
