import 'angular-block-ui';
import 'angular-gettext';
import { defaultTo, get, map, values } from 'lodash/fp';
import api, { ApiService } from '../../../../common/api/api.service';
import appeals, { AppealsService } from '../../appeals.service';
import appealsShow, { AppealsShowService } from '../show.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../../../common/users/users.service';

export class ExcludedController {
    appealId: string;
    blockUI: IBlockUIService;
    data: any[];
    meta: any;
    page: number;
    pageSize: number;
    reasons: any;
    watcher: () => void;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private appeals: AppealsService,
        private appealsShow: AppealsShowService,
        private api: ApiService,
        blockUI: IBlockUIService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private serverConstants: ServerConstantsService, // used in view
        users: UsersService,
    ) {
        this.blockUI = blockUI.instances.get('appealsExcluded');
        this.pageSize = defaultTo(25, users.getCurrentOptionValue('page_size_appeal'));
        this.page = 1;
        this.reasons = {
            gave_more_than_pledged_range: this.gettextCatalog.getString(
                'May have given a special gift in the last 3 months',
            ),
            started_giving_range: this.gettextCatalog.getString('May have joined my team in the last 3 months'),
            pledge_amount_increased_range: this.gettextCatalog.getString(
                'May have increased their giving in the last 3 months',
            ),
            stopped_giving_range: this.gettextCatalog.getString('May have stopped giving for the last 2 months'),
            no_appeals: this.gettextCatalog.getString('"Send Appeals?" set to No'),
            pledge_late_by: this.gettextCatalog.getString('May have missed a gift in the last 30-90 days'),
        };
    }
    $onInit() {
        this.watcher = this.$rootScope.$on('appeal:refresh', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher();
    }
    $onChanges() {
        this.appealsShow.deselectAll();
        if (this.appealId) {
            this.load();
        }
    }
    load(page: number = this.page): ng.IPromise<void> {
        this.blockUI.start();
        return this.api
            .get(`appeals/${this.appealId}/excluded_appeal_contacts`, {
                include: 'contact',
                fields: {
                    contact: 'name,pledge_amount,pledge_currency,pledge_frequency',
                },
                per_page: 20,
                page,
                sort: 'contact.name',
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug(`excluded contacts page ${page}`, data);
                this.data = data;
                this.meta = data.meta;
                this.page = page;
                this.blockUI.reset();
            });
    }
    getReasons(rel: any): string[] {
        const keys = values(rel.reasons);
        return map((key) => get(key, this.reasons), keys);
    }
    addExcludedContact(rel: any): ng.IPromise<void> {
        return this.appeals.addContact(this.appealId, rel.contact, true).then(() => {
            this.$rootScope.$emit('appeal:refresh');
            this.$rootScope.$emit('appeal:refreshCount');
        });
    }
    pageSizeChange(size) {
        this.pageSize = size;
        this.load(1);
    }
}

const Excluded: ng.IComponentOptions = {
    template: require('./excluded.html'),
    controller: ExcludedController,
    bindings: {
        appealId: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.show.excluded.component', [
        'blockUI',
        'gettext',
        appeals,
        appealsShow,
        api,
        serverConstants,
        users,
    ])
    .component('appealExcluded', Excluded).name;
