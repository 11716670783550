export class AlertMessageController {
    showAlert = false;
    text: string;
    localStorageName: string;
    constructor(private $window: ng.IWindowService) {}
    $onInit(): void {
        if (!!this.text && !!this.localStorageName) {
            const isClosed = this.$window.localStorage.getItem(`mpdx-alert-${this.localStorageName}-closed`);
            if (!isClosed && isClosed !== 'true') {
                this.showAlert = true;
            }
        }
    }
    closeAlert(): void {
        this.$window.localStorage.setItem(`mpdx-alert-${this.localStorageName}-closed`, 'true');
        this.showAlert = false;
    }
}

const AlertMessage = {
    controller: AlertMessageController,
    template: require('./alertMessage.html'),
    bindings: {
        text: '@',
        localStorageName: '@',
    },
};

export default angular.module('mpdx.alertmessage', []).component('alertMessage', AlertMessage).name;
