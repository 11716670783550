class CoverController {
    constructor(
        private message: string,
        private title: string,
    ) {
        this.message = message;
        this.title = title;
    }
}

export default angular.module('mpdx.common.modal.cover.controller', []).controller('coverController', CoverController)
    .name;
