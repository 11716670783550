import { StateService } from '@uirouter/core';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import api, { ApiService } from '../common/api/api.service';
import appeals, { AppealsService } from '../tools/appeals/appeals.service';
import contacts, { ContactsService } from '../contacts/contacts.service';
import donations, { DonationsService } from '../reports/donations/donations.service';
import help, { HelpService } from '../common/help/help.service';
import notifications, { NotificationsService } from './notifications/notifications.service';
import people, { PeopleService } from '../contacts/show/people/people.service';
import preferencesOrganizations, { OrganizationsService } from '../preferences/organizations/organizations.service';
import rewritehandoff, { RewriteHandoffService } from '../common/rewritehandoff/rewritehandoff.service';
import rewriteRoutes from '../rewriteRoutes';
import search, { SearchService } from '../common/search/search.service';
import session, { SessionService } from '../common/session/session.service';
import statusPage, { StatusPageService } from '../bottom/status/status.service';
import tasks, { TasksService } from '../tasks/tasks.service';
import tools, { ToolsService } from '../tools/tools.service';
import users, { UsersService } from '../common/users/users.service';

export class MenuController {
    setup: boolean;
    showCoaching: boolean;
    adminUrl: string;
    sidekiqUrl: string;
    watcher0: () => void;
    watcher1: () => void;
    watcher2: () => void;
    hover: string;
    hovering: boolean;
    mediaDarkIcon: string = process.env.MEDIA_DARK_ICON;
    rewriteRoutes = rewriteRoutes;
    constructor(
        private $location: ng.ILocationService, // used in view
        private $rootScope: ng.IRootScopeService,
        private $window: ng.IWindowService,
        private $state: StateService, // used in view
        private accounts: AccountsService, // used in view
        private appeals: AppealsService, // used in view
        private api: ApiService,
        private contacts: ContactsService, // used in view
        private donations: DonationsService, // used in view
        private help: HelpService, // used in view
        private notifications: NotificationsService,
        private people: PeopleService, // used in view
        private preferencesOrganizations: OrganizationsService,
        private search: SearchService, // used in view,
        private session: SessionService, // used in view
        private statusPage: StatusPageService, // used in view
        private tasks: TasksService, // used in view
        private tools: ToolsService,
        private users: UsersService, // used in view
        private rewritehandoff: RewriteHandoffService,
    ) {
        this.adminUrl = `${process.env.OAUTH_URL}/admin?access_token=${this.$window.localStorage.getItem('token')}`;
        this.sidekiqUrl = `${process.env.OAUTH_URL}/sidekiq?access_token=${this.$window.localStorage.getItem('token')}`;
    }
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('document:click', () => {
            this.hover = null;
            this.hovering = false;
        });

        this.watcher1 = this.$rootScope.$on('accountListUpdated', () => {
            this.tools.getAnalytics(true);
            this.notifications.getUnreadTotal();
        });

        this.watcher2 = this.$rootScope.$on('accountListAdded', () => {
            this.checkCoaching();
        });

        if (!this.setup) {
            this.tools.getAnalytics();
            this.notifications.getUnreadTotal();
            this.checkCoaching();
            this.preferencesOrganizations.load();
        }
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
        this.watcher2();
    }
    checkCoaching() {
        return this.api
            .get({
                url: 'user/account_list_coaches',
                data: {
                    per_page: 1,
                },
                type: 'account_lists',
            })
            .then((data: any) => {
                this.showCoaching = data.length > 0;
            });
    }
    setHover(hover: string, hovering = this.hovering) {
        this.hover = hover;
        this.hovering = hovering;
    }
}

const Menu = {
    controller: MenuController,
    template: require('./menu.html'),
    bindings: {
        setup: '<',
    },
};

export default angular
    .module('mpdx.menu.component', [
        'gettext',
        'ui.router',
        accounts,
        api,
        appeals,
        contacts,
        donations,
        help,
        notifications,
        people,
        preferencesOrganizations,
        search,
        session,
        statusPage,
        tasks,
        tools,
        users,
        rewritehandoff,
    ])
    .component('menu', Menu).name;
