import '@uirouter/angularjs';
import 'angular-gettext';
import help, { HelpService } from '../common/help/help.service';

class TasksController {
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
    ) {}
    $onInit() {
        if (this.help.variables().HS_TASKS_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_TASKS_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }
    }
}

const Tasks = {
    controller: TasksController,
    template: require('./tasks.html'),
    bindings: {
        group: '<',
    },
};

export default angular.module('mpdx.tasks.component', ['gettext', help]).component('tasks', Tasks).name;
