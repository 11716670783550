import { has, reduce, unionBy } from 'lodash/fp';
import api, { ApiService } from '../../common/api/api.service';

export class NotificationsService {
    data: any;
    meta: any;
    listLoadCount: number;
    page: number;
    unreadTotal: number;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private api: ApiService,
    ) {
        this.unreadTotal = 0;
        this.listLoadCount = 0;
        this.data = [];
    }
    load(page = 1): ng.IPromise<any> {
        const reset = page === 1;
        if (!reset && this.data.length > 0 && page <= this.page) {
            return this.$q.resolve();
        }
        let currentCount;
        this.page = page;
        if (reset) {
            this.meta = {};
            this.listLoadCount++;
            this.data = [];
            currentCount = angular.copy(this.listLoadCount);
        }

        return this.api
            .get({
                url: 'user/notifications',
                data: {
                    page,
                    include: 'notification,notification.notification_type,notification.contact,notification.donation',
                    sort: '-created_at',
                    fields: {
                        contact: 'name,avatar',
                        donation: 'amount,currency',
                        notification: 'cleared,event_date,contact,donation,notification_type',
                        notification_type: 'type,description',
                        user_notification: 'read,notification',
                    },
                    filter: {
                        account_list_id: this.api.account_list_id,
                    },
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug(`notifications ${this.page}`, data);
                if (reset && currentCount !== this.listLoadCount) {
                    return;
                }
                this.meta = data.meta;
                const newNotifications = angular.copy(data);
                this.data = reset ? newNotifications : unionBy('id', this.data, newNotifications);
            });
    }
    read(userNotification): ng.IPromise<void> {
        if (userNotification.read) {
            return this.$q.resolve();
        }
        return this.api
            .put({
                url: `user/notifications/${userNotification.id}`,
                data: {
                    id: userNotification.id,
                    read: true,
                },
                type: 'user_notifications',
            })
            .then(() => {
                if (this.unreadTotal > 0) {
                    this.unreadTotal -= 1;
                }
            });
    }
    readAll() {
        return this.api
            .put({
                url: 'user/notifications/read',
            })
            .then(() => {
                this.data = reduce(
                    (result, userNotification) => {
                        userNotification.read = true;
                        result.push(userNotification);
                        return result;
                    },
                    [],
                    this.data,
                );
                this.unreadTotal = 0;
            });
    }
    getUnreadTotal(): ng.IPromise<number> {
        return this.api
            .get({
                url: 'user/notifications',
                data: {
                    filter: {
                        read: false,
                        account_list_id: this.api.account_list_id,
                    },
                    per_page: 0,
                },
            })
            .then((data: any) => {
                this.unreadTotal = data.meta.pagination.total_count;
                return this.unreadTotal;
            });
    }
    loadMoreNotifications(): ng.IPromise<any> {
        if (has('pagination.total_pages', this.meta) && this.page >= this.meta.pagination.total_pages) {
            return this.$q.resolve();
        }
        return this.load(this.page + 1);
    }
}

export default angular.module('mpdx.menu.notifications.service', [api]).service('notifications', NotificationsService)
    .name;
