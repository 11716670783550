import contacts, { ContactsService } from '../../../contacts.service';

export class FixPledgeModalController {
    appName = process.env.APP_NAME || 'MPDX';
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private contacts: ContactsService,
        private contact: any,
    ) {}
    save(): ng.IPromise<any> {
        this.contact.pledge_amount = null;
        this.contact.pledge_frequency = null;
        return this.contacts.save(this.contact).then(() => {
            this.$scope.$hide();
        });
    }
}

export default angular
    .module('mpdx.contacts.show.info.fixPledge.controller', [contacts])
    .controller('contactsShowInfoFixPledgeModalController', FixPledgeModalController).name;
