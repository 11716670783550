import * as moment from 'moment';
import { defaultTo, floor, get, map, reduce, round, set, sortBy, sum, toNumber } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import help, { HelpService } from '../../../common/help/help.service';

interface IBaseParams {
    received: number;
    pledged: number;
    total: number;
    date: moment.Moment;
}
interface IBaseCollectionParams {
    start: IBaseParams;
    end: IBaseParams;
    months: number;
}

export class CommitmentsController {
    accountList: any;
    colors: any[];
    data: any;
    datasetOverride: any[];
    endDate: moment.Moment;
    labels: string[];
    loading: boolean;
    months: number;
    monthlyGoal: number;
    monthlyAverage: number;
    goal: number;
    options: any;
    series: string[];
    startDate: moment.Moment;
    showHelpButton = true;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private help: HelpService,
    ) {
        this.series = [
            gettextCatalog.getString('Commitments Received'),
            gettextCatalog.getString('Commitments Not Received'),
            gettextCatalog.getString('MPD Goal Over Time'),
        ];
        this.colors = [
            { backgroundColor: '#D77328' },
            { backgroundColor: '#05699B' },
            { backgroundColor: 'rgba(199, 199, 199, 0.5)' },
        ];
        this.goal = 0;
        if (!this.help.variables().HS_COACHING_COMMITMENTS?.length) {
            this.showHelpButton = false;
        }
    }
    $onChanges() {
        this.loading = true;
        if (this.accountList) {
            this.startDate = moment(this.accountList.active_mpd_start_at).startOf('month');
            this.endDate = moment(this.accountList.active_mpd_finish_at).endOf('month');
            const months = Math.abs(this.startDate.diff(this.endDate, 'months'));
            const goal = toNumber(defaultTo(0, this.accountList.active_mpd_monthly_goal));
            this.goal = goal;
            if (goal > 0 && months > 0) {
                this.monthlyGoal = goal / months;
            } else {
                this.monthlyGoal = goal;
            }
            const label = `${this.gettextCatalog.getString('Commitment Amount')} (${
                this.accountList.default_currency
            })`;
            this.options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                            barThickness: 40,
                        },
                    ],
                    yAxes: [
                        {
                            stacked: true,
                            scaleLabel: {
                                display: true,
                                labelString: label,
                            },
                            ticks: {
                                beginAtZero: true,
                                userCallback: (value) => value.toLocaleString(),
                            },
                        },
                    ],
                },
                elements: {
                    line: {
                        tension: 0,
                    },
                    point: {
                        radius: 0,
                        hitRadius: 50,
                        hoverRadius: 5,
                    },
                },
                tooltips: {
                    itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                    callbacks: {
                        label: (tooltipItem, data) => this.generateTooltip(tooltipItem, data),
                    },
                },
            };
            this.datasetOverride = [
                {
                    type: 'bar',
                },
                {
                    type: 'bar',
                },
                {
                    type: 'line',
                },
            ];
            this.load();
        }
    }
    generateTooltip(item, data) {
        const label = get('label', data.datasets[item.datasetIndex]);
        const newVal = defaultTo(0, get(`data[${item.index}]`, data.datasets[item.datasetIndex])).toLocaleString();
        return `${label}: ${newVal}`;
    }
    load(): ng.IPromise<void> {
        return this.loadBaseData().then((base) => {
            if (this.goal > 0) {
                const min = floor((base.start.total - defaultTo(0, this.goal) / 2) / 100) * 100;
                this.options = set('scales.yAxes[0].ticks.min', min > 0 ? min : 0, this.options);
            }
            return this.api
                .get('reports/pledge_histories', {
                    filter: {
                        account_list_id: this.accountList.id,
                    },
                })
                .then((data) => {
                    const resorted = sortBy((data) => data.start_date, data);
                    /* istanbul ignore next */
                    this.$log.debug('reports/pledge_histories', data);
                    this.data = this.mutateData(resorted, base);
                    this.labels = map((item) => moment(item.id, 'YYYY-MM-DD').format('MMM YY'), resorted);
                    /* istanbul ignore next */
                    this.$log.debug('reports/pledge_histories mutated', this.data);
                    this.loading = false;
                });
        });
    }
    loadBaseData(): ng.IPromise<IBaseCollectionParams> {
        let base = { start: null, end: null, months: 0 };
        return this.$q.all([this.loadStartData(), this.loadEndData()]).then((result) => {
            base.start = result[0];
            base.end = result[1];
            base.months = Math.abs(base.start.date.diff(base.end.date, 'months'));
            this.monthlyAverage = base.months > 0 ? (base.end.total - base.start.total) / base.months : 0;
            return base;
        });
    }
    loadStartData(): ng.IPromise<IBaseParams> {
        if (this.startDate && moment().isSameOrAfter(this.startDate)) {
            return this.loadSinglePeriodData(this.startDate.endOf('month'));
        } else {
            return this.$q.resolve({
                received: 0,
                pledged: 0,
                total: 0,
                date: this.startDate || moment(),
            });
        }
    }
    loadEndData(): ng.IPromise<IBaseParams> {
        if (this.endDate) {
            let date;
            if (moment().isSameOrAfter(this.endDate)) {
                date = this.endDate.endOf('month');
            } else {
                date = moment().endOf('month');
            }
            return this.loadSinglePeriodData(date);
        } else {
            return this.$q.resolve({
                received: 0,
                pledged: 0,
                total: 0,
                date: this.endDate || moment(),
            });
        }
    }
    loadSinglePeriodData(date: moment.Moment): ng.IPromise<IBaseParams> {
        return this.api
            .get('reports/pledge_histories', {
                filter: {
                    end_date: date.locale('en').format('YYYY-MM-DD'),
                    range: '1m',
                    account_list_id: this.accountList.id,
                },
            })
            .then((data) => {
                const base = defaultTo({ received: 0, pledged: 0 }, data[0]);
                const received = round(toNumber(base.received));
                const pledged = round(toNumber(base.pledged));
                return {
                    received,
                    pledged,
                    total: received + pledged,
                    date,
                };
            });
    }
    mutateData(data: any, base: IBaseCollectionParams): number[][] {
        let arr: number[][] = map((item: any) => {
            const received = round(toNumber(item.received));
            const pledged = round(toNumber(item.pledged));
            return [received, pledged];
        }, data);
        arr = reduce(
            (result, items) => {
                items.forEach((item, index) => {
                    if (!result[index]) {
                        result[index] = [];
                    }
                    result[index].push(item);
                });
                return result;
            },
            [],
            arr,
        );
        if (this.monthlyGoal > 0 && this.startDate && this.endDate) {
            arr.push(this.monthlyGoalArray(data, base));
        }
        return arr;
    }
    monthlyGoalArray(data: any, base: IBaseCollectionParams): number[] {
        let goalSum = 0;
        const startInMonthOfEnd: boolean = this.startDate.isSame(this.endDate, 'month');
        const startDate: moment.Moment = startInMonthOfEnd ? this.startDate.startOf('month') : this.startDate;
        const endDate: moment.Moment = startInMonthOfEnd ? this.endDate.endOf('month') : this.endDate;
        return map((item: any) => {
            const isBetween = this.isBetween(item.start_date, item.end_date, startDate, endDate);
            goalSum = isBetween ? sum([goalSum, this.monthlyGoal]) : this.isAfter(item.end_date) ? goalSum : 0;
            const returnableGoal = round(goalSum);
            return defaultTo(returnableGoal, base.start.total + returnableGoal);
        }, data);
    }
    isBetween(start: string, end: string, startToCompare: moment.Moment, endToCompare: moment.Moment) {
        return (
            (moment(start).isSameOrAfter(startToCompare) && moment(start).isSameOrBefore(endToCompare)) ||
            (moment(end).isSameOrAfter(startToCompare) && moment(end).isSameOrBefore(endToCompare))
        );
    }
    isAfter(date: string) {
        return moment(date).isSameOrAfter(this.endDate);
    }
    showHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_COACHING_COMMITMENTS));
    }
}

const Commitments: ng.IComponentOptions = {
    template: require('./commitments.html'),
    controller: CommitmentsController,
    bindings: {
        accountList: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.commitments.component', ['gettext', api, help])
    .component('monthlyCommitments', Commitments).name;
