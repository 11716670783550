import 'angular-gettext';
import { isEmpty } from 'lodash/fp';
import { StateParams, StateService } from '@uirouter/core';
import alerts, { AlertsService } from '../common/alerts/alerts.service';
import api, { ApiService } from '../common/api/api.service';
import preferencesOrganizations, { OrganizationsService } from '../preferences/organizations/organizations.service';
import rewritehandoff, { RewriteHandoffService } from '../common/rewritehandoff/rewritehandoff.service';
import rewriteRoutes from '../rewriteRoutes';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../common/users/users.service';

class AcceptInviteController {
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private api: ApiService,
        private preferencesOrganizations: OrganizationsService,
        private rewritehandoff: RewriteHandoffService,
        private users: UsersService,
    ) {}
    $onInit() {
        if (this.$state.current.name === 'acceptOrganizationInvite') {
            this.acceptOrganizationInvite();
        } else {
            this.acceptAccountListInvite();
        }
    }
    private acceptAccountListInvite(): ng.IPromise<void> {
        const errorMessage = this.gettextCatalog.getString(
            'Unable to accept invite. Try asking the account holder to resend the invite.',
        );
        if (
            !isEmpty(this.$stateParams.code) &&
            !isEmpty(this.$stateParams.account_list_id) &&
            !isEmpty(this.$stateParams.id)
        ) {
            const successMessage = this.gettextCatalog.getString('Accepted invite to account successfully.');
            return this.api
                .put({
                    url: `account_lists/${this.$stateParams.account_list_id}/invites/${this.$stateParams.id}/accept`,
                    data: {
                        id: this.$stateParams.id,
                        code: this.$stateParams.code,
                    },
                    type: 'account_list_invites',
                    errorMessage,
                    successMessage,
                })
                .then(() => {
                    this.users.getCurrent(true);
                    this.$rootScope.$emit('accountListAdded');
                    this.$state.go('home');
                })
                .catch((err) => {
                    this.$state.go('home');
                    throw err;
                });
        } else {
            return this.$q.reject('').catch((err) => {
                this.alerts.addAlert(errorMessage, 'danger', 10);
                this.$state.go('home');
                throw err;
            });
        }
    }
    private acceptOrganizationInvite(): ng.IPromise<ng.IPromise<void> | void> {
        const errorMessage = this.gettextCatalog.getString(
            'Unable to accept invite. Try asking the organization admin to resend the invite.',
        );
        if (
            !isEmpty(this.$stateParams.code) &&
            !isEmpty(this.$stateParams.organization_id) &&
            !isEmpty(this.$stateParams.id)
        ) {
            const successMessage = this.gettextCatalog.getString(
                'Accepted invite to administer an organization successfully.',
            );
            return this.api
                .put({
                    url: `organizations/${this.$stateParams.organization_id}/invites/${this.$stateParams.id}/accept`,
                    data: {
                        id: this.$stateParams.id,
                        code: this.$stateParams.code,
                    },
                    type: 'organization_invites',
                    errorMessage,
                    successMessage,
                })
                .then(() => {
                    return this.preferencesOrganizations.load(true).then(() => {
                        if (this.rewritehandoff.isEarlyAdopter()) {
                            this.rewritehandoff.handleHandOff(rewriteRoutes.settingsOrganizations);
                        } else {
                            this.$state.go('preferences.organizations');
                        }
                    });
                })
                .catch((err) => {
                    this.$state.go('home');
                    throw err;
                });
        } else {
            return this.$q.reject('').catch((err) => {
                this.alerts.addAlert(errorMessage, 'danger', 10);
                this.$state.go('home');
                throw err;
            });
        }
    }
}

const AcceptInvite = {
    controller: AcceptInviteController,
    template: require('./acceptInvite.html'),
};

export default angular
    .module('mpdx.acceptInvite.component', [
        uiRouter,
        'gettext',
        alerts,
        api,
        preferencesOrganizations,
        rewritehandoff,
        users,
    ])
    .component('acceptInvite', AcceptInvite).name;
