const rewriteRoutes = {
    home: '/',
    tasks: '/tasks',
    contacts: '/contacts',
    contactsFlows: '/contacts/flows',
    contactsFlowsSetup: '/contacts/flows/setup',
    contactDetails: '/contacts/?tab=ContactDetails',
    contactDonations: '/contacts/?tab=Donations',
    contactNotes: '/contacts/?tab=Notes',
    contactReferrals: '/contacts/?tab=Referrals',
    contactTasks: '/contacts/?tab=Tasks',
    reportsSalaryCurrency: '/reports/salaryCurrency',
    reportsPartnerCurrency: '/reports/partnerCurrency',
    reportsResponsibilityCenters: '/reports/responsibilityCenters',
    reportsExpectedMonthlyTotal: '/reports/expectedMonthlyTotal',
    reportsDonations: '/reports/donations',
    reportsDesignationAccounts: '/reports/designationAccounts',
    reportsPartnerGivingAnalysis: '/reports/partnerGivingAnalysis',
    settingsPreferences: '/settings/preferences',
    settingsNotifications: '/settings/notifications',
    settingsIntegrations: '/settings/integrations',
    settingsManageCoaches: '/settings/manageCoaches',
    settingsManageAccounts: '/settings/manageAccounts',
    settingsAdmin: '/settings/admin',
    settingsOrganizations: '/settings/organizations',
    settingsOrganizationsContacts: '/settings/organizations/contacts',
    settingsOrganizationsAccountLists: '/settings/organizations/accountLists',
    modalAddContact: '/?modal=AddContact',
    modalAddMultipleContacts: '/?modal=AddMultipleContacts',
    modalAddDonation: '/?modal=AddDonation',
};

export default rewriteRoutes;
