import * as Bowser from 'bowser';
import { StateService } from '@uirouter/core';
import uiRouter from '@uirouter/angularjs';
import unsupported, { UnsupportedService } from './unsupported.service';

export class UnsupportedController {
    isAndroid: boolean;
    isIos: boolean;
    appName = process.env.APP_NAME || 'MPDX';
    mediaDarkIcon: string = process.env.MEDIA_DARK_ICON;
    productionSiteUrl: string = process.env.PRODUCTION_SITE_URL || 'https://mpdx.org';
    supportEmail: string = process.env.SUPPORT_EMAIL || 'support@mpdx.org';
    appStoreUrl = process.env.APP_STORE_URL || 'https://itunes.apple.com/us/app/mpdx/id571838934?mt=8';
    googlePlayUrl = process.env.GOOGLE_PLAY_URL || 'https://play.google.com/store/apps/details?id=org.mpdx';
    constructor(
        private $state: StateService,
        private $window: ng.IWindowService,
        private unsupported: UnsupportedService,
    ) {}
    $onInit() {
        if (!this.unsupported.showUnsupported()) {
            this.$state.go('home');
            return;
        }
        const browser = Bowser.getParser(this.$window.navigator.userAgent);
        this.isAndroid = browser.getOSName() === 'Android';
        this.isIos = browser.getOSName() === 'iOS';
    }
}

const Unsupported = {
    template: require('./unsupported.html'),
    controller: UnsupportedController,
};

export default angular
    .module('mpdx.unsupported.component', [uiRouter, unsupported])
    .component('unsupported', Unsupported).name;
