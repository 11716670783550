import { find, get, isUndefined, reject } from 'lodash/fp';
import { v1 as uuidv1 } from 'uuid';
import contacts, { ContactsService } from '../../../contacts.service';
import designationAccounts, {
    DesignationAccountsService,
} from '../../../../common/designationAccounts/designationAccounts.service';

class DonorAccountController {
    donorAccount: any;
    onSave: any;
    constructor(
        private contacts: ContactsService,
        private designationAccounts: DesignationAccountsService,
    ) {}
    $onInit(): void {
        if (isUndefined(this.donorAccount.persisted)) {
            this.donorAccount.persisted = !!this.donorAccount.id;
        }
        if (!this.donorAccount.persisted) {
            this.donorAccount.id = uuidv1();
        }
        if (this.donorAccount.organization && !this.donorAccount.organization.name) {
            const organization = find(
                { id: this.donorAccount.organization.id },
                this.designationAccounts.organizations,
            );
            this.donorAccount.organization.name = get('name', organization);
        }
    }
    remove(): void {
        if (this.donorAccount.persisted) {
            this.donorAccount._destroy = '1';
            this.save();
        } else {
            this.contacts.current.donor_accounts = reject(
                { id: this.donorAccount.id },
                this.contacts.current.donor_accounts,
            );
        }
    }
    save(): ng.IPromise<void> {
        return this.onSave().then(() => {
            this.donorAccount.persisted = true;
        });
    }
}
const DonorAccount = {
    controller: DonorAccountController,
    template: require('./donorAccount.html'),
    bindings: {
        contact: '<',
        donorAccount: '=',
        onSave: '&',
    },
};

export default angular
    .module('mpdx.contacts.show.details.donorAccount.component', [contacts, designationAccounts])
    .component('contactDonorAccount', DonorAccount).name;
