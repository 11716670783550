import help, { HelpService } from '../common/help/help.service';

class BottomController {
    year: number;
    showStatusPage = !!process.env.STATUS_PAGE_API_URL;
    showHelpLink = !!process.env.BEACON_TOKEN;
    whatsNewUrl?: string = process.env.WHATS_NEW_URL;
    privacyPolicyUrl?: string = process.env.PRIVACY_POLICY_URL;
    termsOfUseUrl?: string = process.env.TERMS_OF_USE_URL;
    constructor(private help: HelpService) {
        this.year = new Date().getFullYear();
    }
    openHelp() {
        this.help.open();
    }
}

const Bottom = {
    template: require('./bottom.html'),
    controller: BottomController,
};

// don't add help dependency here. Uses mock for testing
export default angular.module('mpdx.bottom.component', [help]).component('bottom', Bottom).name;
