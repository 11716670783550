import 'angular-gettext';
import * as moment from 'moment';
import {
    assign,
    concat,
    curry,
    defaultTo,
    find,
    flow,
    get,
    includes,
    isFunction,
    isNil,
    map,
    pull,
    reduce,
    union,
} from 'lodash/fp';
import { flattenCompactAndJoin, flattenCompactAndJoinSemicolon } from '../common/fp/flattenCompactAndJoin';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import alerts, { AlertsService } from '../common/alerts/alerts.service';
import api, { ApiService } from '../common/api/api.service';
import contactFilter, { ContactFilterService } from './sidebar/filter/filter.service';
import contactsTags, { ContactsTagsService } from './sidebar/filter/tags/tags.service';
import createPatch from '../common/fp/createPatch';
import filters, { FiltersService } from '../common/filters/filters.service';
import isNilOrEmpty from '../common/fp/isNilOrEmpty';
import joinComma from '../common/fp/joinComma';
import mailchimp, { MailchimpService } from '../preferences/integrations/mailchimp/mailchimp.service';
import modal, { ModalService } from '../common/modal/modal.service';
import relationshipId from '../common/fp/relationshipId';
import rewritehandoff, { RewriteHandoffService } from '../common/rewritehandoff/rewritehandoff.service';
import rewriteRoutes from '../rewriteRoutes';
import serverConstants, { ServerConstantsService } from '../common/serverConstants/serverConstants.service';

export class ContactsService {
    activeDrawer: string;
    activeTab: string;
    current: any;
    initialState: any;
    selectedContactIds: string[];
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private alerts: AlertsService,
        private api: ApiService,
        private contactFilter: ContactFilterService,
        private contactsTags: ContactsTagsService,
        private filters: FiltersService,
        private mailchimp: MailchimpService,
        private modal: ModalService,
        private serverConstants: ServerConstantsService,
        private rewritehandoff: RewriteHandoffService,
    ) {
        this.current = null;
        this.selectedContactIds = [];
        this.activeDrawer = 'details';
        this.activeTab = 'donations';
    }
    get(id: string): ng.IPromise<any> {
        return this.api
            .get({
                url: `contacts/${id}`,
                data: {
                    include:
                        'addresses,donor_accounts,donor_accounts.organization,primary_person,' +
                        'contact_referrals_to_me,account_list,user',
                    fields: {
                        contacts:
                            'avatar,church_name,envelope_greeting,greeting,last_donation,lifetime_donations,' +
                            'likely_to_give,locale,magazine,name,next_ask,no_appeals,notes,notes_saved_at,' +
                            'pledge_amount,pledge_currency,pledge_frequency,pledge_received,' +
                            'pledge_start_date,send_newsletter,square_avatar,status,status_valid,suggested_changes,' +
                            'tag_list,timezone,website,addresses,contact_referrals_by_me,contact_referrals_to_me,' +
                            'contacts_that_referred_me,donor_accounts,primary_person,no_gift_aid,timezone,' +
                            'account_list,starred_at,source,relationship_code,user,preferred_contact_method',
                        addresses:
                            'city,country,created_at,end_date,geo,historic,location,metro_area,postal_code,' +
                            'primary_mailing_address,region,remote_id,seasonal,source,source_donor_account,' +
                            'start_date,state,street,updated_at,updated_in_db_at,valid_values',
                        donor_accounts: 'account_number,organization',
                        organizations: 'name',
                        account_lists: '',
                        users: '',
                    },
                },
                deSerializationOptions: relationshipId(['contacts', 'people']), // for contacts_referred_by_me, contacts_that_referred_me and primary_person
            })
            .then((data: any) => {
                this.accounts.swap(data.account_list.id);
                data.pledge_amount = parseFloat(data.pledge_amount); // fix bad api serialization as string
                data.donor_accounts = map((donorAccount) => {
                    return { ...donorAccount, persisted: true };
                }, data.donor_accounts);
                if (!isNil(data.pledge_frequency)) {
                    data.pledge_frequency = parseFloat(data.pledge_frequency);
                }
                return data;
            });
    }
    getPrimaryPerson(id: string): ng.IPromise<any> {
        return this.api
            .get({
                url: `contacts/${id}`,
                data: {
                    include: 'primary_person,people.email_addresses,people.phone_numbers',
                    fields: {
                        contacts: 'primary_person',
                        people: 'first_name,last_name,phone_numbers,email_addresses',
                        phone_numbers: 'primary,historic,number',
                        email_addresses: 'primary,historic,email',
                    },
                },
            })
            .then((data: any) => {
                data = data.primary_person;
                /* istanbul ignore next */
                this.$log.debug('primary person', id, data);
                return data;
            });
    }
    search(keyword: string): ng.IPromise<any> {
        return this.api.get({
            url: 'contacts',
            data: {
                filter: {
                    account_list_id: this.api.account_list_id,
                    wildcard_search: keyword,
                },
                fields: {
                    contacts: 'name',
                },
                per_page: 6,
                sort: 'name',
            },
        });
    }
    buildFilterParams(): any {
        return this.filters.buildFilterParams(
            this.contactFilter.defaultParams,
            this.contactFilter.params,
            this.contactFilter.wildcardSearch,
            this.contactsTags.selectedTags,
            this.contactsTags.rejectedTags,
            this.contactsTags.anyTags,
        );
    }
    save(contact: any, successMessage?: string, errorMessage?: string): ng.IPromise<any> {
        if (contact.tag_list) {
            contact.tag_list = joinComma(contact.tag_list); // fix for api mis-match
        }
        return this.api.put(`contacts/${contact.id}`, contact, successMessage, errorMessage).then((data) => {
            if (contact?.tag_list && typeof contact?.tag_list === 'string') {
                contact.tag_list = contact.tag_list.split(','); // convert back to array.
            }
            if (contact.name) {
                this.$rootScope.$emit('contactCreated');
            }
            this.$rootScope.$emit('contactUpdated', contact);
            return data;
        });
    }
    saveCurrent() {
        const source = angular.copy(this.current); // to avoid onChanges changes
        const target = angular.copy(this.initialState); // to avoid onChanges changes
        const patch = createPatch(target, source);
        this.$log.debug('contact patch', patch);
        const errorMessage = this.gettextCatalog.getString('Unable to save changes.');
        const successMessage = this.gettextCatalog.getString('Changes saved successfully.');

        if (patch.user && patch.user.id === null) {
            // user removed case
            patch.user = { id: 'none' }; // fudge around api shortcoming
        }

        return this.save(patch, successMessage, errorMessage).then(() => {
            if (patch.tag_list) {
                const tags = patch.tag_list.split(',');
                this.$rootScope.$emit('contactTagsAdded', { tags });
                this.contactsTags.addTag({ tags });
            }
            if (patch.id === this.initialState.id) {
                this.initialState = assign(this.initialState, patch);
            }
        });
    }
    create(contact: any): ng.IPromise<any> {
        contact.account_list = { id: this.api.account_list_id };
        return this.api.post('contacts', contact).then((data) => {
            this.$rootScope.$emit('contactCreated');
            return data;
        });
    }
    addBulk(contacts: any[]): ng.IPromise<any> {
        return this.api
            .post({
                url: 'contacts/bulk',
                data: contacts,
                type: 'contacts',
                fields: {
                    contacts: '',
                },
            })
            .then(() => {
                this.$rootScope.$emit('contactCreated');
            });
    }
    addReferrals(contact: any, contacts: any[]): ng.IPromise<any> {
        return this.api
            .put(`contacts/${contact.id}`, {
                id: contact.id,
                contacts_referred_by_me: contacts,
            })
            .then(() => {
                this.$rootScope.$emit('contactCreated');
            });
    }
    isSelected(contactId: string): boolean {
        return includes(contactId, this.selectedContactIds);
    }
    selectContact(contactId: string): void {
        if (includes(contactId, this.selectedContactIds)) {
            this.selectedContactIds = pull(contactId, this.selectedContactIds);
        } else {
            this.selectedContactIds = union(this.selectedContactIds, [contactId]);
        }
    }
    clearSelectedContacts(): void {
        this.selectedContactIds = [];
    }
    hideContact(contact: any): ng.IPromise<any> {
        const message = this.gettextCatalog.getString(
            'Are you sure you wish to hide the selected contact? Hiding a contact in MPDX actually sets the contact status to "Never Ask".',
        );
        return this.modal.confirm(message).then(() => {
            return this.save({
                id: contact.id,
                status: 'Never Ask',
            }).then(() => {
                contact.status = 'Never Ask';
                this.$rootScope.$emit('contactHidden', contact.id);
                return contact;
            });
        });
    }
    bulkSave(contacts: any[]): ng.IPromise<any> {
        return this.api.put('contacts/bulk', contacts);
    }
    bulkEditFields(model: any, contacts: any[]): ng.IPromise<any> {
        contacts = reduce((result, contact) => concat(result, assign({ id: contact.id }, model)), [], contacts);
        return this.bulkSave(contacts);
    }
    merge(winnersAndLosers: any): ng.IPromise<any> {
        return this.api
            .post({
                url: 'contacts/merges/bulk',
                data: winnersAndLosers,
                type: 'contacts',
            })
            .then((data: any) => {
                if (isFunction(data.success)) {
                    data.success();
                }
                return data;
            });
    }
    openAddressModal(contact: any, address: any): ng.IPromise<any> {
        return this.modal.open({
            template: require('./show/addresses/address/modal/modal.html'),
            controller: 'addressModalController',
            locals: {
                contact,
                address: address || { source: 'MPDX' },
            },
            resolve: {
                0: () => this.serverConstants.load(['assignable_locations']),
            },
        });
    }
    saveAddress(contactId: string, address: any): ng.IPromise<any> {
        return this.api.put(`contacts/${contactId}/addresses/${address.id}`, address);
    }
    addAddress(contactId: string, address: any): ng.IPromise<any> {
        return this.api.post(`contacts/${contactId}/addresses`, address);
    }
    deleteAddress(contactId: string, addressId: string): ng.IPromise<any> {
        const message = this.gettextCatalog.getString('Are you sure you wish to delete this address?');
        return this.modal.confirm(message).then(() => {
            return this.api.delete(`contacts/${contactId}/addresses/${addressId}`);
        });
    }
    openNewContactModal(): ng.IPromise<any> {
        if (this.rewritehandoff.isEarlyAdopter()) {
            return this.rewritehandoff.handleHandOff(rewriteRoutes.modalAddContact);
        } else {
            return this.modal.open({
                template: require('./new/new.html'),
                controller: 'contactNewModalController',
            });
        }
    }
    openMultipleAddModal(contact = null): ng.IPromise<any> {
        if (this.rewritehandoff.isEarlyAdopter()) {
            return this.rewritehandoff.handleHandOff(rewriteRoutes.modalAddMultipleContacts);
        } else {
            return this.modal.open({
                template: require('./multiple/multiple.html'),
                controller: 'multipleContactController',
                backdrop: 'static',
                locals: {
                    contact,
                },
            });
        }
    }
    fixPledgeAmountAndFrequencies(data: any): any[] {
        return map(
            (contact) =>
                assign(contact, {
                    pledge_amount: isNil(contact.pledge_amount) ? null : parseFloat(contact.pledge_amount),
                    pledge_frequency: isNil(contact.pledge_frequency)
                        ? null
                        : this.serverConstants.getPledgeFrequencyValue(contact.pledge_frequency),
                }),
            angular.copy(data),
        );
    }
    openShowInfoFixPledgeModal(contact: any): ng.IPromise<any> {
        return this.modal.open({
            template: require('./show/info/fixPledge/fixPledge.html'),
            controller: 'contactsShowInfoFixPledgeModalController',
            locals: {
                contact,
            },
        });
    }
    getEmails(errorMessage: string): ng.IPromise<any> {
        return this.api
            .get(
                'contacts',
                {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        newsletter: 'email',
                        status: 'active',
                    },
                    include: 'people,people.email_addresses',
                    fields: {
                        contacts: 'people',
                        people: 'deceased,email_addresses,optout_enewsletter',
                        email_addresses: 'email,primary',
                    },
                    per_page: 25000,
                },
                undefined,
                errorMessage,
            )
            .then((data) => {
                return this.mapEmails(data);
            });
    }
    getEmailsFromPeople(data: any): any {
        const getEmail = get('email');
        const findPrimary = find({ primary: true });
        const getEmailFromPrimary = flow(findPrimary, getEmail);
        return map((person) => {
            return person.deceased || person.optout_enewsletter ? null : getEmailFromPrimary(person.email_addresses);
        }, data);
    }
    mapEmails(data: any): string {
        return flattenCompactAndJoin((contact) => this.getEmailsFromPeople(contact.people), data);
    }
    mapOutlookEmails(data: any): string {
        return flattenCompactAndJoinSemicolon((contact) => this.getEmailsFromPeople(contact.people), data);
    }
    exportMailchimp(mailchimpListId: string, selectedContactIds: string[]): ng.IPromise<void> {
        const alert = curry((message: string) =>
            this.alerts.addAlert(this.gettextCatalog.getString(message), 'danger'),
        );
        const result = this.cantExportToMailChimp(mailchimpListId);
        return result ? alert(result as string) : this.doExportToMailChimp(mailchimpListId, selectedContactIds);
    }
    private cantExportToMailChimp(mailchimpListId: string): boolean | string {
        return defaultTo(this.isSelectedPrimary(mailchimpListId), this.isMailChimpListUndefined());
    }
    private isMailChimpListUndefined(): string | null {
        const message = 'No primary Mailchimp list defined. Please select a list in preferences.';
        return isNilOrEmpty(get('primary_list_id', this.mailchimp.data)) ? message : null;
    }
    private isSelectedPrimary(mailchimpListId: string): boolean | string {
        const message = 'Please select a list other than your primary Mailchimp list.';
        return get('primary_list_id', this.mailchimp.data) === mailchimpListId ? message : false;
    }
    private doExportToMailChimp(mailchimpListId: string, selectedContactIds: string[]): ng.IPromise<any> {
        const successMessage = this.gettextCatalog.getString('Contact(s) successfully exported to Mailchimp');
        const errorMessage = this.gettextCatalog.getString('Unable to add export contact(s) to Mailchimp');
        return this.api.post({
            url: `contacts/export_to_mail_chimp?mail_chimp_list_id=${mailchimpListId}`,
            type: 'export_to_mail_chimps',
            data: {
                filter: {
                    account_list_id: this.api.account_list_id,
                    contact_ids: selectedContactIds,
                },
            },
            doSerialization: false,
            successMessage,
            errorMessage,
        });
    }
    getTotalCount(): ng.IPromise<number> {
        // only used when search is empty
        return this.api
            .get('contacts', {
                filter: {
                    account_list_id: this.api.account_list_id,
                },
                per_page: 0,
            })
            .then((data: any) => {
                return data.meta.pagination.total_count;
            });
    }
    star(contact: any): ng.IPromise<any> {
        return this.api
            .put(`contacts/${contact.id}`, {
                id: contact.id,
                starred_at: !contact.starred_at ? moment().format() : null,
            })
            .then((data) => {
                return data;
            });
    }
}

export default angular
    .module('mpdx.contacts.service', [
        'gettext',
        accounts,
        alerts,
        api,
        contactFilter,
        contactsTags,
        filters,
        mailchimp,
        modal,
        serverConstants,
        rewritehandoff,
    ])
    .service('contacts', ContactsService).name;
