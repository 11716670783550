import 'angular-gettext';
import 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { findIndex, first, isEqual, last, map, pullAt } from 'lodash/fp';
import { HotkeysProvider } from 'angular-hotkeys'; // eslint-disable-line import/no-duplicates

export class SelectableListController {
    anchorId: string;
    data: { id: string }[];
    selectedIds: string[];
    selectedWithAnchorIds: string[];
    lastAddedId: string;
    constructor(
        private $scope: ng.IScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private hotkeys: HotkeysProvider,
    ) {
        this.selectedWithAnchorIds = [];
    }
    $onInit(): void {
        this.hotkeys
            .bindTo(this.$scope)
            .add({
                combo: ['home', 'mod+up'],
                description: this.gettextCatalog.getString('Select the first record'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectFirst(event);
                },
            })
            .add({
                combo: ['shift+home', 'mod+shift+up'],
                description: this.gettextCatalog.getString('Add range (from first to current record) to selection'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectFirst(event);
                },
            })
            .add({
                combo: ['end', 'mod+down'],
                description: this.gettextCatalog.getString('Select the last record'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectLast(event);
                },
            })
            .add({
                combo: ['shift+end', 'mod+shift+down'],
                description: this.gettextCatalog.getString('Add range (from current to last record) to selection'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectLast(event);
                },
            })
            .add({
                combo: 'up',
                description: this.gettextCatalog.getString('Select the previous record'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectPrevious(event);
                },
            })
            .add({
                combo: 'shift+up',
                description: this.gettextCatalog.getString('Add previous record to selection'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectPrevious(event);
                },
            })
            .add({
                combo: 'down',
                description: this.gettextCatalog.getString('Select the next record'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectNext(event);
                },
            })
            .add({
                combo: 'shift+down',
                description: this.gettextCatalog.getString('Add next record to selection'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectNext(event);
                },
            })
            .add({
                combo: 'mod+a',
                description: this.gettextCatalog.getString('Select all records'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectAll();
                },
            })
            .add({
                combo: 'esc',
                description: this.gettextCatalog.getString('Deselect all records'),
                callback: (event) => {
                    event.preventDefault();
                    this.selectNone();
                },
            });
    }
    click(event: JQueryEventObject, item) {
        this.select(event, item);
    }
    private selectFirst(event): void {
        const item = first(this.data);
        if (item) {
            this.select(event, item);
        }
    }
    private selectLast(event) {
        const item = last(this.data);
        if (item) {
            this.select(event, item);
        }
    }
    private selectNext(event) {
        if (this.selectedIds.length && this.anchorId !== null) {
            const index = findIndex({ id: this.lastAddedId }, this.data);
            const item = this.data[index + 1];
            if (item) {
                this.select(event, item);
            }
        }
    }
    private selectPrevious(event) {
        if (this.selectedIds.length && this.anchorId !== null) {
            const index = findIndex({ id: this.lastAddedId }, this.data);
            const item = this.data[index - 1];
            if (item) {
                this.select(event, item);
            }
        }
    }
    private selectAll() {
        this.selectedIds = map('id', this.data);
        this.setAnchorId(null);
    }
    private selectNone() {
        this.selectedIds = [];
        this.lastAddedId = null;
        this.setAnchorId(null);
    }
    private select(event, item) {
        if (event.shiftKey) {
            if (this.selectedIds.length === 0) {
                this.setAnchorId(item.id);
            }
            this.selectedWithAnchorIds.forEach((id) => {
                this.remove(id);
            });
            this.selectedWithAnchorIds = [];

            this.items(this.anchorId, item.id).forEach((item) => {
                this.selectedWithAnchorIds = [...this.selectedWithAnchorIds, item.id];
                this.add(item.id);
            });
        } else if (event.ctrlKey || event.metaKey) {
            this.toggle(item.id);
        } else {
            this.selectedIds = [item.id];
            this.lastAddedId = item.id;
            this.setAnchorId(item.id);
        }
    }
    private toggle(id: string) {
        if (this.selected(id)) {
            this.remove(id);
            if (this.selectedIds.length === 0) {
                this.setAnchorId(null);
            }
        } else {
            this.setAnchorId(id);
            this.add(id);
        }
    }
    private add(id: string): void {
        if (!this.selected(id)) {
            this.selectedIds = [...this.selectedIds, id];
            this.lastAddedId = id;
        }
    }
    private remove(id: string): void {
        const index = this.selectedIds.indexOf(id);
        this.selectedIds = pullAt(index, this.selectedIds);
    }
    private selected(id: string): boolean {
        const index = this.selectedIds.indexOf(id);
        return index > -1;
    }
    private setAnchorId(id: string): void {
        const items = this.items(first(this.selectedWithAnchorIds), last(this.selectedWithAnchorIds));
        if (this.anchorId === null || findIndex({ id }, items) === -1 || isEqual(this.selectedIds, [id])) {
            this.anchorId = id;
            this.selectedWithAnchorIds = [];
        }
    }
    private items(firstId: string, lastId: string): any[] {
        const firstSelectedIndex = findIndex({ id: firstId }, this.data);
        const lastSelectedIndex = findIndex({ id: lastId }, this.data);
        const min = Math.min(firstSelectedIndex, lastSelectedIndex);
        const max = Math.max(firstSelectedIndex, lastSelectedIndex);

        let items = this.data.slice(min, max + 1);

        if (firstSelectedIndex > lastSelectedIndex) {
            items = items.reverse();
        }

        return items;
    }
}

const SelectableList = {
    controller: SelectableListController,
    template: require('./selectableList.html'),
    transclude: true,
    bindings: {
        data: '<',
        selectedIds: '=',
    },
};

export default angular
    .module('mpdx.common.selectableList.component', ['cfp.hotkeys', 'gettext'])
    .component('selectableList', SelectableList).name;
