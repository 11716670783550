import { StateService } from '@uirouter/core';
import preferencesOrganizations, { OrganizationsService } from '../organizations/organizations.service';
import rewritehandoff, { RewriteHandoffService } from '../../common/rewritehandoff/rewritehandoff.service';
import rewriteRoutes from '../../rewriteRoutes';
import session, { SessionService } from '../../common/session/session.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../../common/users/users.service';

class SidebarController {
    disabled: boolean;
    constructor(
        private $state: StateService,
        private preferencesOrganizations: OrganizationsService, // used in view
        private session: SessionService, // used in view
        private users: UsersService, // used in view
        private rewritehandoff: RewriteHandoffService, // used in view
    ) {}
    go(state) {
        if (!this.disabled) {
            this.$state.go(state);
        }
    }
    goToOrganizations() {
        if (this.rewritehandoff.isEarlyAdopter()) {
            this.rewritehandoff.handleHandOff(rewriteRoutes.settingsOrganizations);
        } else {
            this.go('preferences.organizations');
        }
    }
}

const Sidebar = {
    controller: SidebarController,
    template: require('./sidebar.html'),
    bindings: {
        disabled: '<',
    },
};

export default angular
    .module('mpdx.preferences.sidebar.component', [uiRouter, preferencesOrganizations, session, users, rewritehandoff])
    .component('preferencesSidebar', Sidebar).name;
