import { StateParams } from '@uirouter/core';
import accounts, { AccountsService } from '../accounts/accounts.service';
import api, { ApiService } from '../api/api.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../users/users.service';

export class RewriteHandoffService {
    newOrigin = process.env.REWRITE_DOMAIN;
    useRewrite = process.env.USE_REWRITE === 'true';
    constructor(
        private $window: ng.IWindowService,
        private $q: ng.IQService,
        private $log: ng.ILogService,
        private accounts: AccountsService,
        private api: ApiService,
        private users: UsersService,
    ) {}
    handleHandOff(path: string, $stateParams?: StateParams, $event?: MouseEvent) {
        const deferred = this.$q.defer();
        try {
            if (!this.isEarlyAdopter()) {
                deferred.resolve();
            } else {
                if ($event) {
                    $event.preventDefault();
                }
                const url = new URL(`${this.newOrigin}/api/mpdx-web-handoff`);
                if ($stateParams) {
                    for (const [key, value] of Object.entries($stateParams)) {
                        if (typeof value === 'string') {
                            url.searchParams.append(key, value);
                        }
                    }
                }
                url.searchParams.append('accountListId', this.accounts.current.id);
                url.searchParams.append('path', path);
                url.searchParams.append('userId', this.users.current.id);
                if (this.$window.localStorage.getItem('impersonatorToken')) {
                    url.searchParams.append('token', this.$window.localStorage.getItem('impersonatorToken'));
                    url.searchParams.append('impersonate', this.$window.localStorage.getItem('token'));
                } else {
                    url.searchParams.append('token', this.$window.localStorage.getItem('token'));
                }

                if (!$stateParams?.contactId) {
                    this.$window.location.assign(url.toString());
                    deferred.reject();
                } else {
                    // If contact ID, ensure we grab the correct accountListId for the contact.
                    this.api
                        .get({
                            url: `contacts/${$stateParams.contactId}`,
                            data: {
                                include: 'account_list',
                                fields: {
                                    contacts: 'account_list',
                                },
                            },
                        })
                        .then((data: any) => {
                            url.searchParams.set('accountListId', data.account_list.id);
                            this.$window.location.assign(url.toString());
                            deferred.reject();
                        });
                }
            }
        } catch (error) {
            this.$log.error('RewriteHandoffService Error', error);
            deferred.resolve();
        }
        return deferred.promise;
    }
    isEarlyAdopter() {
        return this.useRewrite && this.accounts?.current?.tester === true;
    }
    hasLoggedintoRewriteApp() {
        if (!this.isEarlyAdopter()) return false;
        const redirectCookie = document.cookie?.split('mpdx-handoff.logged-in=')[1]?.split(';')[0];
        return redirectCookie === 'true';
    }
}

export default angular
    .module('mpdx.common.rewritehandoff.service', [uiRouter, accounts, users, api])
    .service('rewritehandoff', RewriteHandoffService).name;
