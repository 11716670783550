import 'angular-gettext';
import { compact, concat, isEmpty, join, map, reduce } from 'lodash/fp';
import { IAddress, IContact, IPerson } from '../../common/types';
import { StateService } from '@uirouter/core';
import { v1 as uuidv1 } from 'uuid';
import api, { ApiService } from '../../common/api/api.service';
import contacts, { ContactsService } from '../contacts.service';
import uiRouter from '@uirouter/angularjs';

interface IModel {
    id: string;
    first_name?: string;
    last_name?: string;
    spouse_first_name?: string;
    email?: string;
    phone?: string;
    address?: IAddress;
    postal_code?: string;
}

export class MultipleController {
    models: IModel[];
    modalTitle: string;
    constructor(
        private $q: ng.IQService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private $state: StateService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private contacts: ContactsService,
        private contact: IContact,
    ) {
        this.models = map(() => {
            return {
                id: uuidv1(),
                address: {} as IAddress,
            };
        }, Array(10));
        if (this.contact) {
            this.modalTitle = this.gettextCatalog.getString('Add Referrals');
        } else {
            this.modalTitle = this.gettextCatalog.getString('Add Multiple Contacts');
        }
    }
    save(): ng.IPromise<void> {
        const contacts = reduce(
            (result, model) => {
                const contact = this.serializeModel(model);
                if (contact) {
                    result = concat(result, contact);
                }
                return result;
            },
            [],
            this.models,
        );
        if (contacts.length === 0) {
            return this.$q.resolve().then(() => {
                this.$scope.$hide();
            });
        } else {
            return this.contacts.addBulk(contacts).then(() => {
                this.$scope.$hide();
                if (this.contact) {
                    this.$state.go('contacts', {
                        filters: { referrer: this.contact.id },
                    });
                }
            });
        }
    }
    updateAddress(address: IAddress, street: string, index: number): void {
        const model = this.models[index];
        if (street) {
            model.address = {
                street,
            } as IAddress;
        } else if (address) {
            model.address = address;
        }
        this.models[index] = model;
    }
    serializeModel(model: IModel): IContact | undefined {
        if (model.first_name || model.last_name) {
            let contact: IContact = {
                id: uuidv1(),
                account_list: { id: this.api.account_list_id },
                name: join(', ', compact([model.last_name, model.first_name])),
            };
            let person: IPerson = {
                id: uuidv1(),
                first_name: model.first_name,
                last_name: model.last_name,
                primary: true,
            };
            if (model.email) {
                person.email_addresses = [{ id: uuidv1(), email: model.email, primary: true }];
            }
            if (model.phone) {
                person.phone_numbers = [{ id: uuidv1(), number: model.phone, primary: true }];
            }
            if (model.spouse_first_name) {
                let spouse: any = {
                    id: uuidv1(),
                    first_name: model.spouse_first_name,
                    last_name: model.last_name,
                };
                const firstNames = join(' and ', compact([model.first_name, model.spouse_first_name]));
                contact.name = join(', ', compact([model.last_name, firstNames]));
                contact.people = [person, spouse];
            } else {
                contact.people = [person];
            }
            if (model.address && !isEmpty(model.address)) {
                contact.addresses = [{ ...model.address, id: uuidv1(), primary_mailing_address: true }];
            }
            if (this.contact) {
                contact.contact_referrals_to_me = [
                    {
                        id: uuidv1(),
                        referred_by: {
                            id: this.contact.id,
                        } as IContact,
                    },
                ];
            }
            return contact;
        }
    }
}

export default angular
    .module('mpdx.contacts.multiple.controller', [uiRouter, 'gettext', api, contacts])
    .controller('multipleContactController', MultipleController).name;
