import { indexOf } from 'lodash/fp';
import { v1 as uuidv1 } from 'uuid';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../common/api/api.service';
import appeals, { AppealsService } from '../appeals.service';
import modal, { ModalService } from '../../../common/modal/modal.service';

export enum FlowStatus {
    Excluded = 'excluded',
    Asked = 'asked',
    NotReceived = 'not_received',
    ReceivedNotProcessed = 'received_not_processed',
    Processed = 'processed',
}

interface IPledge {
    id: string;
    status: 'not_received' | 'received_not_processed' | 'processed';
}

interface IAppealContact {
    id: string;
}

interface IExcludedAppealContact {
    id: string;
}

interface IContact {
    pledges: IPledge[];
    appeal_contacts: IAppealContact[];
    excluded_appeal_contacts: IExcludedAppealContact[];
}
export interface IFlowsConfig {
    color: string;
    id: string;
    name: string;
    status: FlowStatus;
}
export class FlowsService {
    draggableConfig: IFlowsConfig;
    draggable: any;
    config: IFlowsConfig[];
    selectedContactIds: string[];
    constructor(
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private api: ApiService,
        private appeals: AppealsService,
        private modal: ModalService,
    ) {
        this.selectedContactIds = [];
        this.config = [
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Excluded'),
                status: FlowStatus.Excluded,
                color: 'color-danger',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Asked'),
                status: FlowStatus.Asked,
                color: 'color-text',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Committed'),
                status: FlowStatus.NotReceived,
                color: 'color-committed',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Received‌⁠'),
                status: FlowStatus.ReceivedNotProcessed,
                color: 'color-received',
            },
            {
                id: uuidv1(),
                name: this.gettextCatalog.getString('Given'),
                status: FlowStatus.Processed,
                color: 'color-given',
            },
        ];
    }
    setDraggable(draggableConfig, draggable): void {
        this.draggableConfig = draggableConfig;
        this.draggable = draggable;
    }
    contactSelected(id: string): boolean {
        return indexOf(id, this.selectedContactIds) > -1;
    }
    toggleContact(id: string): void {
        const index = indexOf(id, this.selectedContactIds);
        if (index > -1) {
            this.selectedContactIds.splice(index, 1);
        } else {
            this.selectedContactIds.push(id);
        }
    }
    clearSelectedContacts(): void {
        this.selectedContactIds = [];
    }
    setContactStatus(appealId: string, contact: IContact, status: FlowStatus): ng.IPromise<void> {
        return this.transition(
            appealId,
            contact,
            contact.pledges[0],
            contact.appeal_contacts[0],
            contact.excluded_appeal_contacts[0],
            status,
        ).then((changedFlowStatus) => {
            this.updateColumns(changedFlowStatus, contact);
        });
    }
    transition(
        appealId: string,
        contact: IContact,
        pledge: IPledge,
        appealContact: IAppealContact,
        excludedAppealContact: IExcludedAppealContact,
        status: FlowStatus,
    ): ng.IPromise<FlowStatus> {
        let message, path, patch;
        const appealsPath = `appeals/${appealId}`;
        switch (status) {
            case FlowStatus.Excluded:
                message = this.gettextCatalog.getString(
                    'You cannot exclude a contact from this appeal. ' +
                        'Would you like to remove them from this appeal instead?',
                );
                path = `${appealsPath}/appeal_contacts/${appealContact.id}`;
                return this.modal.confirm(message).then(() => {
                    return this.api.delete(path).then(() => FlowStatus.Excluded);
                });
            case FlowStatus.Asked:
                if (pledge) {
                    message = this.gettextCatalog.getString(
                        'Moving this contact will result in the connected commitment being deleted. Are you sure?',
                    );
                    return this.modal.confirm(message).then(() => {
                        return this.appeals.removePledge(pledge.id).then(() => FlowStatus.Asked);
                    });
                } else {
                    message = this.gettextCatalog.getString(
                        'You will not be able to exclude this contact once you add them to this appeal. ' +
                            'Instead, you will be able to remove them from it. Are you sure?',
                    );
                    path = `${appealsPath}/excluded_appeal_contacts/${excludedAppealContact.id}`;
                    return this.modal.confirm(message).then(() => {
                        return this.appeals.addContact(appealId, contact, true).then(() => FlowStatus.Asked);
                    });
                }
            case FlowStatus.NotReceived:
            case FlowStatus.ReceivedNotProcessed:
                if (pledge) {
                    path = `account_lists/${this.api.account_list_id}/pledges/${pledge.id}`;
                    patch = {
                        id: pledge.id,
                        status,
                    };
                    return this.api.put(path, patch).then((data) => {
                        // The server can prevent a contact from being added to ReceivedNotProcessed.
                        // If the user tries to assign contact to ReceivedNotProcessed
                        // but the server rejected, show one of these warnings
                        if (data.status === FlowStatus.NotReceived && status === FlowStatus.ReceivedNotProcessed) {
                            this.alerts.addAlert(
                                'Unable to move contact here as gift has not been received by Cru.',
                                'warning',
                                10,
                            );
                        } else if (
                            data.status === FlowStatus.Processed &&
                            (status === FlowStatus.ReceivedNotProcessed || status === FlowStatus.NotReceived)
                        ) {
                            this.alerts.addAlert(
                                'Unable to move contact here as this gift is already proccessed.',
                                'warning',
                                10,
                            );
                        }
                        // Return the status from the server rather than the status requested by the user.
                        // As it can lead to confusion.
                        return data.status || status;
                    });
                } else {
                    return this.modal
                        .open({
                            template: require('../show/addPledge/add.html'),
                            controller: 'addPledgeController',
                            locals: {
                                appealId,
                                contact,
                                pledge: {
                                    status,
                                },
                            },
                        })
                        .then((pledge: IPledge) => {
                            return pledge.status as FlowStatus;
                        });
                }
            case FlowStatus.Processed:
                return this.modal
                    .open({
                        template: require('./updateDonations/updateDonations.html'),
                        controller: 'appealsFlowsUpdateDonationsController',
                        locals: {
                            appealId,
                            contact,
                            pledge,
                        },
                    })
                    .then((pledge: IPledge) => {
                        return pledge.status as FlowStatus;
                    });
        }
    }
    updateColumns(status: FlowStatus, contact: IContact): void {
        this.$rootScope.$emit('appeal:refresh');
        this.$rootScope.$emit('appealsFlowsRefresh', status);
        this.$rootScope.$emit('appealsFlowsRemove', status, contact);
    }
    addContact(appealId: string, contact: IContact): ng.IPromise<any> {
        return this.appeals.addContact(appealId, contact).then(() => {
            this.updateColumns(FlowStatus.Asked, contact);
        });
    }
}

export default angular
    .module('mpdx.tools.appeals.flows.service', ['gettext', alerts, api, appeals, modal])
    .service('appealsFlows', FlowsService).name;
