import { isString } from 'lodash/fp';

const siteUrl = process.env.SITE_URL || 'https://mpdx.org';

function stripMpdx() {
    return function (val) {
        if (!isString(val)) {
            return '';
        }
        return val.replace(siteUrl, '');
    };
}

export default angular.module('mpdx.common.stripMpdx', []).filter('stripMpdx', stripMpdx).name;
