import { StateService } from '@uirouter/core';
import alerts, { AlertsService } from '../common/alerts/alerts.service';
import apioauth, { ApiOauthService } from '../common/auth/apioauth.service';
import okta, { OktaService } from '../common/auth/okta.service';
import session, { SessionService } from '../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

class LoginController {
    year: number;
    appName = process.env.APP_NAME || 'MPDX';
    marketingSiteUrl: string;
    signingIn = true;
    mediaLightIcon: string = process.env.MEDIA_LIGHT_ICON;
    showOktaOAuth = process.env.AUTH_PROVIDER === 'OKTA';
    showApiOAuth = process.env.AUTH_PROVIDER === 'API_OAUTH';
    apiOAuthImage = process.env.API_OAUTH_IMAGE;
    apiOAuthVisibleName = process.env.API_OAUTH_VISIBLE_NAME ?? 'SSO';
    constructor(
        private $state: StateService,
        private session: SessionService, // used in view
        private okta: OktaService, // used in view
        private apioauth: ApiOauthService, // used in view
        authManager: any,
        private alerts: AlertsService,
        private $scope: ng.IScope,
    ) {
        if (authManager.isAuthenticated()) {
            this.$state.go('home');
        }
        if (!process.env.AUTH_PROVIDER) {
            this.handleError('Please configure an auth provider. AUTH_PROVIDER env is not defined');
        }
        this.year = new Date().getFullYear();
        this.marketingSiteUrl = process.env.MARKETING_SITE_URL;

        if (this.showApiOAuth) {
            this.apioauth
                .handleRedirect()
                .then((result) => (this.signingIn = result))
                .catch((error) => {
                    this.handleError(error);
                });
        }
        if (this.showOktaOAuth) {
            this.okta
                .handleRedirect()
                .then((result) => (this.signingIn = result))
                .catch((error) => this.handleError(error));
        }
    }
    signIn() {
        this.signingIn = true;
        this.okta.signIn().catch((error) => this.handleError(error));
    }
    async signInApiOauth() {
        this.signingIn = true;
        await this.apioauth.signIn().catch((error) => this.handleError(error));
    }
    handleError(error) {
        this.signingIn = false;
        this.alerts.addAlert(error, 'danger', 10);
        this.$scope.$apply();
        throw error;
    }
}

const Login = {
    controller: LoginController,
    template: require('./login.html'),
};

export default angular
    .module('mpdx.login.component', [uiRouter, session, okta, apioauth, alerts])
    .component('login', Login).name;
