import 'angular-gettext';
import { find, get } from 'lodash/fp';

interface IUser {
    id: string;
    first_name: string;
    last_name: string;
    email_addresses: {
        email: string;
        primary: boolean;
    }[];
}

export class HelpService {
    constructor(private $window: ng.IWindowService) {
        this.init();
    }
    init(): void {
        process.env.BEACON_TOKEN && this.beacon('init', process.env.BEACON_TOKEN);
    }
    open(): void {
        try {
            this.beacon('open');
        } catch (err) {
            this.$window.open(process.env.BEACON_DOCS_LINK, '_blank');
        }
    }
    article(articleId: string): void {
        this.beacon('article', articleId);
    }
    identifyUser(user: IUser): void {
        if (!user) {
            return;
        }
        const email = get('email', find(['primary', true], user.email_addresses));
        this.beacon('identify', {
            id: user.id,
            email,
            name: `${user.first_name} ${user.last_name}`,
        });
    }
    suggest(articleIds: string[]): void {
        this.beacon('suggest', articleIds);
    }
    beacon(name, options = null) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (process.env.NODE_ENV === 'test' ? (_method, _options) => {} : (window as any).Beacon)(name, options);
    }
    convertSuggestionsToArray(suggestionVariables): any[] {
        try {
            return suggestionVariables.split(',');
        } catch {
            return [];
        }
    }
    variables(): any {
        return {
            HS_COACHING_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_COACHING_SUGGESTIONS),
            HS_COACHING_APPOINTMENTS_AND_RESULTS: process.env.HS_COACHING_APPOINTMENTS_AND_RESULTS,
            HS_COACHING_COMMITMENTS: process.env.HS_COACHING_COMMITMENTS,
            HS_COACHING_OUTSTANDING_RECURRING_COMMITMENTS: process.env.HS_COACHING_OUTSTANDING_RECURRING_COMMITMENTS,
            HS_COACHING_OUTSTANDING_SPECIAL_NEEDS: process.env.HS_COACHING_OUTSTANDING_SPECIAL_NEEDS,
            HS_COACHING_ACTIVITY: process.env.HS_COACHING_ACTIVITY,
            HS_COACHING_ACTIVITY_SUMMARY: process.env.HS_COACHING_ACTIVITY_SUMMARY,
            HS_CONTACTS_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_CONTACTS_SUGGESTIONS),
            HS_CONTACTS_CONTACT_SUGGESTIONS: this.convertSuggestionsToArray(
                process.env.HS_CONTACTS_CONTACT_SUGGESTIONS,
            ),
            HS_SETTINGS_PREFERENCES_SUGGESTIONS: this.convertSuggestionsToArray(
                process.env.HS_SETTINGS_PREFERENCES_SUGGESTIONS,
            ),
            HS_SETTINGS_ACCOUNTS_SUGGESTIONS: this.convertSuggestionsToArray(
                process.env.HS_SETTINGS_ACCOUNTS_SUGGESTIONS,
            ),
            HS_SETTINGS_COACHES_SUGGESTIONS: this.convertSuggestionsToArray(
                process.env.HS_SETTINGS_COACHES_SUGGESTIONS,
            ),
            HS_SETTINGS_SERVICES_SUGGESTIONS: this.convertSuggestionsToArray(
                process.env.HS_SETTINGS_SERVICES_SUGGESTIONS,
            ),
            HS_SETTINGS_SERVICES_FIND_ORGANIZATION: process.env.HS_SETTINGS_SERVICES_FIND_ORGANIZATION,
            HS_SETUP_FIND_ORGANIZATION: process.env.HS_SETUP_FIND_ORGANIZATION,
            HS_TOOLS_IMPORT_CVS_ERROR: process.env.HS_TOOLS_IMPORT_CVS_ERROR,
            HS_REPORTS_DONATIONS_MONTHLY_ACTIVITY: process.env.HS_REPORTS_DONATIONS_MONTHLY_ACTIVITY,
            HS_REPORTS_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_REPORTS_SUGGESTIONS),
            HS_TASKS_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_TASKS_SUGGESTIONS),
            HS_TOOLS_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_TOOLS_SUGGESTIONS),
            HS_HOME_SUGGESTIONS: this.convertSuggestionsToArray(process.env.HS_HOME_SUGGESTIONS),
        };
    }
}

export default angular.module('mpdx.common.help', ['gettext']).service('help', HelpService).name;
