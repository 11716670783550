import 'angular-gettext';
import * as moment from 'moment';
import { defaultTo, find, flattenDeep, get, map, sumBy, take, takeRight, uniq } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import designationAccounts, {
    DesignationAccountsService,
} from '../../../common/designationAccounts/designationAccounts.service';
import help, { HelpService } from '../../../common/help/help.service';
import joinComma from '../../../common/fp/joinComma';
import uiRouter from '@uirouter/angularjs';

class ChartController {
    accountList: any;
    chartData: any;
    colors: any[];
    data: any;
    empty: boolean;
    endDate: moment.Moment;
    inCoaching: boolean;
    inContact: boolean;
    labels: string[];
    loading: boolean;
    options: any;
    series: string[];
    startDate: moment.Moment;
    watcher0: any;
    watcher1: any;
    counter: number;
    showHelpButton = true;
    constructor(
        private $state: StateService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $filter: ng.IFilterService,
        private $log: ng.ILogService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private contacts: ContactsService,
        private designationAccounts: DesignationAccountsService,
        private help: HelpService,
    ) {
        this.counter = 0;
        this.loading = true;
        this.colors = [
            { backgroundColor: '#fdb800' },
            { backgroundColor: '#df7d00' },
            { backgroundColor: '#909b9c' },
            { backgroundColor: '#666062' },
            { backgroundColor: '#36b1ca' },
            { backgroundColor: '#00729a' },
        ];
        if (!this.help.variables().HS_REPORTS_DONATIONS_MONTHLY_ACTIVITY?.length) {
            this.showHelpButton = false;
        }
    }
    $onInit() {
        this.watcher0 = this.$rootScope.$on('donationUpdated', () => {
            this.$onChanges();
        });
        this.watcher1 = this.$rootScope.$on('designationAccountSelectorChanged', () => {
            this.$onChanges();
        });
    }
    $onDestroy() {
        this.watcher0();
        this.watcher1();
    }
    $onChanges() {
        if (this.accountList) {
            this.load();
        }
    }
    load() {
        this.loading = true;
        this.endDate = moment().endOf('month');
        let params: any = {
            endDate: this.endDate,
        };
        if (this.inContact && this.contacts.current.donor_accounts) {
            params.donorAccountId = map('id', this.contacts.current.donor_accounts).join();
            if (params.donorAccountId === '') {
                this.data = [];
                this.loading = false;
                return this.$q.reject('');
            }
            if (this.contacts.current.pledge_currency) {
                params.displayCurrency = this.contacts.current.pledge_currency;
            }
            params.range = '24m';
            params.ignoreZeroPeriodsForAverage = true;
        } else {
            params.ignoreCurrentPeriodForAverage = true;
        }
        if (this.designationAccounts.selected.length > 0) {
            params.designationAccountId = joinComma(this.designationAccounts.selected);
        }
        this.counter++;
        const counter = this.counter;
        return this.getDonationChart(params).then((data: any) => {
            if (this.counter !== counter) {
                return;
            }
            this.loading = false;
            this.data = data;
            if (this.inContact) {
                this.labels = map(
                    (period) => {
                        return moment(period.start_date, 'YYYY-MM-DD').format('MMM');
                    },
                    takeRight(12, this.data) as any,
                );
                this.series = [this.gettextCatalog.getString('Last Year'), this.gettextCatalog.getString('This Year')];
                this.chartData = map('converted_total', this.data);
                this.chartData = [take(12, this.chartData), takeRight(12, this.chartData)];
            } else {
                this.series = uniq(
                    flattenDeep(map((period) => map((total) => total.currency, period.totals), this.data)),
                );
                this.labels = map((period) => moment(period.start_date, 'YYYY-MM-DD').format('MMM YY'), data);
                this.chartData = map((currency) => {
                    return map((period) => {
                        return defaultTo(0, get('converted_amount', find({ currency }, period.totals)));
                    }, this.data);
                }, this.series);
            }
            this.options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [
                        {
                            stacked: !this.inContact,
                            gridLines: {
                                display: false,
                            },
                            barThickness: this.inContact ? 20 : 40,
                        },
                    ],
                    yAxes: [
                        {
                            stacked: !this.inContact,
                            ticks: {
                                beginAtZero: true,
                                userCallback: (value) => value.toLocaleString(),
                            },
                            scaleLabel: {
                                display: true,
                                labelString: `${this.gettextCatalog.getString('Amount')} (${
                                    data.meta.display_currency
                                })`,
                            },
                        },
                    ],
                },
                annotation: {
                    annotations: [
                        {
                            type: 'line',
                            mode: 'horizontal',
                            scaleID: 'y-axis-0',
                            value: data.meta.average_ignore_current,
                            borderColor: '#666062',
                            borderWidth: 2,
                        },
                    ],
                    drawTime: 'beforeDatasetsDraw',
                },
                onClick: (event, legendItem) => this.onClick(event, legendItem),
            };

            if (!this.inContact) {
                this.options.tooltips = {
                    callbacks: {
                        label: (tooltipItem, data) => this.generateTooltip(tooltipItem, data),
                    },
                };
                this.options.annotation.annotations = this.options.annotation.annotations.concat([
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: data.meta.total_pledges,
                        borderColor: '#f9b625',
                        borderWidth: 2,
                    },
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: data.meta.monthly_goal,
                        borderColor: '#3eb1c8',
                        borderWidth: 2,
                    },
                ]);
            }

            this.empty = sumBy('converted_total', this.data) === 0;
        });
    }
    generateTooltip(item, data) {
        const label = get('label', data.datasets[item.datasetIndex]);
        const newVal = defaultTo(
            0,
            get('converted_amount', find({ currency: label }, this.data[item.index].totals)),
        ).toLocaleString();
        if (newVal <= 0) {
            return undefined;
        }
        return `${label}: ${newVal}`;
    }
    onClick(_event, legendItem) {
        if (legendItem.length === 0 || this.inContact || this.inCoaching) {
            return;
        }
        const startDate = moment(`01 ${legendItem[0]._model.label}`, 'DD MMM YY');
        this.$state.go('reports.donations', { startDate });
    }
    getDonationChart({
        donorAccountId = null,
        displayCurrency = this.accountList.currency,
        designationAccountId = null,
        endDate = moment().endOf('month'),
        range = '13m',
    } = {}) {
        const params = {
            filter: {
                account_list_id: this.accountList.id,
                designation_account_id: designationAccountId,
                display_currency: displayCurrency,
                donor_account_id: donorAccountId,
                end_date: endDate.locale('en').format('YYYY-MM-DD'),
                range,
            },
        };
        return this.api.get('reports/donation_histories', params).then((data) => {
            /* istanbul ignore next */
            this.$log.debug('reports/donation_histories', data);
            return data;
        });
    }
    showHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_REPORTS_DONATIONS_MONTHLY_ACTIVITY));
    }
}

const Chart = {
    controller: ChartController,
    template: require('./chart.html'),
    bindings: {
        accountList: '<',
        inContact: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.reports.donations.chart.component', ['gettext', uiRouter, api, contacts, designationAccounts, help])
    .component('donationsChart', Chart).name;
