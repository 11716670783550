import 'angular-block-ui';
import api, { ApiService } from '../api/api.service';
import contacts, { ContactsService } from '../../contacts/contacts.service';

class EmailController {
    blockUI: IBlockUIService;
    emails: string;
    outlookEmails: string;
    constructor(
        blockUI: IBlockUIService,
        private api: ApiService,
        private gettext: ng.gettext.gettextFunction,
        private contactIds: any[],
        private contacts: ContactsService,
    ) {
        this.blockUI = blockUI.instances.get('contact-export');

        this.getEmails();
    }
    getEmails() {
        this.blockUI.start();
        const errorMessage = this.gettext('Unable to retrieve contacts. Please try again.');
        return this.api
            .get({
                url: 'contacts',
                data: {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        ids: this.contactIds,
                    },
                    include: 'people,people.email_addresses',
                    fields: {
                        contacts: 'people',
                        people: 'deceased,email_addresses,optout_enewsletter',
                        email_addresses: 'email,primary',
                    },
                    per_page: 25000,
                },
                errorMessage,
                overrideGetAsPost: true,
            })
            .then((data) => {
                this.emails = this.contacts.mapEmails(data);
                this.outlookEmails = this.contacts.mapOutlookEmails(data);
                this.blockUI.reset();
            })
            .catch((ex) => {
                this.blockUI.reset();
                throw ex;
            });
    }
}

export default angular
    .module('mpdx.common.exportEmails.controller', ['blockUI', api, contacts])
    .controller('exportEmailsController', EmailController).name;
