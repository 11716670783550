import 'angular-gettext';
import * as moment from 'moment';
import api, { ApiService } from '../../../common/api/api.service';
import help, { HelpService } from '../../../common/help/help.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';

class PledgesController {
    accountListId: string;
    contacts: any[];
    inCoaching: boolean;
    loading: boolean;
    meta: any;
    showHelpButton = true;
    constructor(
        private $log: ng.ILogService,
        private api: ApiService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
        private serverConstants: ServerConstantsService, // used in view
    ) {
        if (!this.help.variables().HS_COACHING_OUTSTANDING_RECURRING_COMMITMENTS?.length) {
            this.showHelpButton = false;
        }
    }
    $onChanges() {
        this.loading = true;
        this.contacts = [];
        this.meta = {};
        this.getContacts();
    }
    private getContacts(page = 1): ng.IPromise<void> {
        return this.api
            .get({
                url: 'contacts',
                method: 'POST',
                page,
                per_page: 20,
                data: {
                    filter: {
                        account_list_id: this.accountListId,
                        pledge: 'outstanding',
                    },
                },
                overrideGetAsPost: true,
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug('coaching/contacts', data);
                this.contacts = data;
                this.meta = data.meta;
                this.loading = false;
            });
    }
    fromNow(date) {
        return moment(date).fromNow();
    }
    showHelp(): void {
        const articleId = this.help.variables().HS_COACHING_OUTSTANDING_RECURRING_COMMITMENTS;
        this.help.article(this.gettextCatalog.getString(articleId));
    }
}

const Pledges = {
    template: require('./pledges.html'),
    controller: PledgesController,
    bindings: {
        accountListId: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.outstandingPledges.component', ['gettext', api, help, serverConstants])
    .component('outstandingPledges', Pledges).name;
