import 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { find, findIndex, reduce, sortBy } from 'lodash/fp';
import { HotkeysProvider } from 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { RawParams, StateDeclaration, StateOrName, StateService } from '@uirouter/core'; // eslint-disable-line import/no-duplicates
import { v1 as uuidv1 } from 'uuid';
import api, { ApiService } from '../api/api.service';
import contactFilter, { ContactFilterService } from '../../contacts/sidebar/filter/filter.service';
import contacts, { ContactsService } from '../../contacts/contacts.service';
import rewritehandoff, { RewriteHandoffService } from '../../common/rewritehandoff/rewritehandoff.service';
import rewriteRoutes from '../../rewriteRoutes';
import search, { SearchService } from './search.service';
import uiRouter from '@uirouter/angularjs';

export enum ESearchResultType {
    ActiveContact,
    HiddenContact,
    ContactsMoreResults,
    State,
    CreateContact,
}

interface ISearchResult {
    id: string;
    to?: StateOrName;
    type: ESearchResultType;
    params?: RawParams;
    active?: boolean;
    name: string;
    description?: string;
}

export class SearchController {
    results: ISearchResult[];
    searchParams: string;
    loading: boolean;
    counter: number;
    constructor(
        private $q: ng.IQService,
        private $state: StateService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService, // used in view
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private contactFilter: ContactFilterService,
        private contacts: ContactsService,
        private hotkeys: HotkeysProvider,
        private search: SearchService, // used in view
        private rewritehandoff: RewriteHandoffService,
    ) {
        this.counter = 0;
        this.loading = false;
        this.results = [];
        this.searchParams = '';
    }
    $onInit(): void {
        this.hotkeys.add({
            combo: 'mod+f',
            description: this.gettextCatalog.getString('Quick Find'),
            callback: (event) => {
                event.preventDefault();
                this.search.open();
            },
        });
    }
    reset(): void {
        this.search.close();
        this.$timeout(() => {
            this.loading = false;
            this.searchParams = '';
            this.results = [];
        }, 500);
    }
    go(result: ISearchResult) {
        switch (result.type) {
            case ESearchResultType.ActiveContact:
            case ESearchResultType.HiddenContact:
                if (this.rewritehandoff.isEarlyAdopter()) {
                    this.rewritehandoff.handleHandOff(`${rewriteRoutes.contacts}/${result.id}`);
                } else {
                    this.$state.go('contacts.show', { contactId: result.id });
                }
                break;
            case ESearchResultType.State:
                this.$state.go(result.to);
                break;
            case ESearchResultType.CreateContact:
                if (this.rewritehandoff.isEarlyAdopter()) {
                    this.rewritehandoff.handleHandOff(rewriteRoutes.modalAddContact);
                } else {
                    this.createContact();
                }
                break;
            case ESearchResultType.ContactsMoreResults:
                this.showMoreResults();
                break;
        }
        this.reset();
    }
    submit(): void {
        const result = find('active', this.results);
        if (result) {
            this.go(result);
        } else {
            this.showMoreResults();
            this.reset();
        }
    }
    onChange(): ng.IPromise<void> {
        this.loading = true;
        if (this.searchParams === '') {
            this.results = [];
            this.loading = false;
            return this.$q.resolve();
        }
        this.results = [
            {
                id: uuidv1(),
                type: ESearchResultType.CreateContact,
                name: this.gettextCatalog.getString('Create a new contact for "{{name}}"', {
                    name: this.searchParams,
                }),
            },
        ];
        this.counter++;
        const counter = this.counter;
        return this.$q
            .all([this.searchContacts(counter), this.searchStates(counter)])
            .then(() => {
                if (this.counter !== counter) {
                    return;
                }
                this.loading = false;
            })
            .catch(() => {
                if (this.counter !== counter) {
                    return;
                }
                this.loading = false;
            });
    }
    searchStates(counter: number): ng.IPromise<void> {
        if (this.counter !== counter) {
            return this.$q.resolve();
        }

        let results = [
            ...this.results,
            ...reduce(
                (results, state: StateDeclaration) => {
                    if (
                        results.length < 3 &&
                        (state as unknown as any).searchable &&
                        (state as unknown as any).title.toLowerCase().search(this.searchParams.toLowerCase()) >= 0
                    ) {
                        results.push({
                            id: uuidv1(),
                            to: state.name,
                            type: ESearchResultType.State,
                            name: (state as unknown as any).title,
                        });
                    }
                    return results;
                },
                [] as ISearchResult[],
                this.$state.get(),
            ),
        ];
        this.results = sortBy('type', results);
        return this.$q.resolve();
    }
    searchContacts(counter: number): ng.IPromise<void> {
        return this.api
            .get({
                url: 'contacts',
                data: {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        status: 'active,null,hidden',
                        wildcard_search: this.searchParams,
                    },
                    fields: {
                        contacts: 'name,status',
                    },
                    per_page: 5,
                },
            })
            .then((data) => {
                if (this.counter !== counter) {
                    return;
                }
                let results = [
                    ...this.results,
                    ...reduce(
                        (results, contact) => {
                            const type =
                                contact.status === 'Never Ask'
                                    ? ESearchResultType.HiddenContact
                                    : ESearchResultType.ActiveContact;
                            results.push({
                                id: contact.id,
                                type,
                                name: contact.name,
                                description: contact.status,
                            });
                            return results;
                        },
                        [] as ISearchResult[],
                        data,
                    ),
                ];
                if (data.length < data.meta.pagination.total_count) {
                    results.push({
                        id: uuidv1(),
                        type: ESearchResultType.ContactsMoreResults,
                        name: this.gettextCatalog.getString('And {{count}} more', {
                            count: data.meta.pagination.total_count - data.length,
                        }),
                    });
                }
                this.results = sortBy('type', results);
            });
    }
    keyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Tab':
                event.shiftKey ? this.selectPrevious() : this.selectNext();
                event.preventDefault();
                break;
            case 'ArrowUp':
                this.selectPrevious();
                event.preventDefault();
                break;
            case 'ArrowDown':
                this.selectNext();
                event.preventDefault();
                break;
            case 'Escape':
                this.reset();
                event.preventDefault();
                break;
        }
    }
    showMoreResults(): void {
        this.contactFilter.wildcardSearch = angular.copy(this.searchParams);
        this.contactFilter.params.status = ['active', 'null', 'hidden'];
        this.$state.go('contacts', {}, { reload: true });
    }
    createContact(): ng.IPromise<any> {
        return this.contacts.create({ name: this.searchParams }).then((contact: any) => {
            this.go({
                id: contact.id,
                type: ESearchResultType.ActiveContact,
                name: contact.name,
            });
        });
    }
    selectNext(): void {
        if (this.results.length > 0) {
            const index = findIndex('active', this.results);
            let item;
            if (index >= 0) {
                item = this.results[index];
                item.active = false;
            }
            if (index === -1 || index === this.results.length - 1) {
                item = this.results[0];
            } else {
                item = this.results[index + 1];
            }
            item.active = true;
        }
    }
    selectPrevious(): void {
        if (this.results.length > 0) {
            const index = findIndex('active', this.results);
            let item;
            if (index >= 0) {
                item = this.results[index];
                item.active = false;
            }
            if (index === -1 || index === 0) {
                item = this.results[this.results.length - 1];
            } else {
                item = this.results[index - 1];
            }
            item.active = true;
        }
    }
}

const Search = {
    controller: SearchController,
    template: require('./search.html'),
};

export default angular
    .module('mpdx.common.search.component', [
        'cfp.hotkeys',
        uiRouter,
        api,
        contactFilter,
        contacts,
        search,
        rewritehandoff,
    ])
    .component('search', Search).name;
