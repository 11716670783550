export class RootController {
    env: string;
    showAlert = !!process.env.ALERT_MESSAGE;
    alertMessage = process.env.ALERT_MESSAGE;
    constructor() {
        this.env = process.env.NODE_ENV;
    }
}

const Root = {
    controller: RootController,
    template: require('./root.html'),
};

export default angular.module('mpdx.root.component', ['ui.router']).component('root', Root).name;
