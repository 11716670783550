import 'angular-gettext';
import { assign, concat, contains, defaultTo, find, get, map, pull } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import appeals, { AppealsService } from '../appeals.service';
import exportContacts, { ExportContactsService } from '../../../contacts/bulkActions/contact/export/export.service';
import fixed from '../../../common/fp/fixed';
import joinComma from '../../../common/fp/joinComma';
import modal, { ModalService } from '../../../common/modal/modal.service';
import relationshipId from '../../../common/fp/relationshipId';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';

export class AppealsShowService {
    selectedContactIds: string[];
    askedCount: number;
    committedCount: number;
    receivedCount: number;
    givenCount: number;
    excludedCount: number;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
        private appeals: AppealsService,
        private exportContacts: ExportContactsService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private modal: ModalService,
        private serverConstants: ServerConstantsService,
    ) {}
    getAppeal(appealId) {
        return this.api.get({
            url: `appeals/${appealId}`,
            deSerializationOptions: relationshipId('contacts'),
        });
    }
    fixPledgeAmount(contacts: any): any[] {
        return map(
            (ref) =>
                assign(ref, {
                    contact: assign(ref.contact, {
                        pledge_amount: fixed(2, defaultTo(0, ref.contact.pledge_amount)),
                    }),
                }),
            contacts,
        );
    }
    addSymbols(data: any[]): any[] {
        return map(
            (ref) =>
                assign(ref, {
                    donations: this.getCurrencySymbols(ref.donations),
                }),
            data,
        );
    }
    getCurrencySymbols(donations: any): any[] {
        return map((donation) => {
            return assign(donation, {
                symbol: this.getCurrencySymbolFromCode(donation.currency),
                converted_symbol: this.getCurrencySymbolFromCode(donation.converted_currency),
            });
        }, donations);
    }
    getCurrencyFromCode(code: string): any {
        return find({ code }, this.serverConstants.data.pledge_currencies);
    }
    getCurrencySymbolFromCode(code: string): string {
        const currency = this.getCurrencyFromCode(code);
        return get('symbol', currency);
    }
    removePledge(pledge: any): ng.IPromise<void> {
        const message = this.gettextCatalog.getString('Are you sure you wish to remove this commitment?');
        return this.modal.confirm(message).then(() =>
            this.appeals.removePledge(pledge.id).then(() => {
                this.$rootScope.$emit('appeal:refresh');
                this.$rootScope.$emit('appeal:refreshCount');
            }),
        );
    }
    selectContact(contactId: string): void {
        this.selectedContactIds = contains(contactId, this.selectedContactIds)
            ? pull(contactId, this.selectedContactIds)
            : concat(this.selectedContactIds, contactId);
    }
    editPledge(pledge: any, appealId: string): void {
        this.modal.open({
            template: require('./editPledge/edit.html'),
            controller: 'editPledgeController',
            locals: {
                appealId,
                pledge,
            },
        });
    }
    deselectAll(): void {
        this.selectedContactIds = [];
    }
    exportEmail(): ng.IPromise<void> {
        return this.modal.open({
            template: require('../../../common/emailExport/email.html'),
            controller: 'exportEmailsController',
            locals: {
                contactIds: this.selectedContactIds,
            },
        });
    }
    exportToCSV(): ng.IPromise<void> {
        return this.exportContacts.create({
            filters: {
                account_list_id: this.api.account_list_id,
                ids: joinComma(this.selectedContactIds),
                status: 'active,hidden,null',
            },
            format: 'csv',
        });
    }
    getAskedCount(appealId): ng.IPromise<number> {
        return this.api
            .get(`appeals/${appealId}/appeal_contacts`, {
                per_page: 0,
                filter: {
                    pledged_to_appeal: false,
                },
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('asked count', data.meta.pagination.total_count);
                this.askedCount = data.meta.pagination.total_count;
                return this.askedCount;
            });
    }
    getCommittedCount(appealId): ng.IPromise<number> {
        return this.api
            .get(`account_lists/${this.api.account_list_id}/pledges`, {
                per_page: 0,
                filter: {
                    appeal_id: appealId,
                    status: 'not_received',
                },
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('pledges committed count', data.meta.pagination.total_count);
                this.committedCount = data.meta.pagination.total_count;
                return this.committedCount;
            });
    }
    getReceivedCount(appealId): ng.IPromise<number> {
        return this.api
            .get(`account_lists/${this.api.account_list_id}/pledges`, {
                per_page: 0,
                filter: {
                    appeal_id: appealId,
                    status: 'received_not_processed',
                },
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('pledges received count', data.meta.pagination.total_count);
                this.receivedCount = data.meta.pagination.total_count;
                return this.receivedCount;
            });
    }
    getGivenCount(appealId): ng.IPromise<number> {
        return this.api
            .get(`account_lists/${this.api.account_list_id}/pledges`, {
                per_page: 0,
                filter: {
                    appeal_id: appealId,
                    status: 'processed',
                },
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('pledges given count', data.meta.pagination.total_count);
                this.givenCount = data.meta.pagination.total_count;
                return this.givenCount;
            });
    }
    getExcludedCount(appealId): ng.IPromise<number> {
        return this.api
            .get(`appeals/${appealId}/excluded_appeal_contacts`, {
                per_page: 0,
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('excluded contacts count', data.meta.pagination.total_count);
                this.excludedCount = data.meta.pagination.total_count;
                return this.excludedCount;
            });
    }
}

export default angular
    .module('mpdx.tools.appeals.show.service', ['gettext', appeals, api, exportContacts, modal, serverConstants])
    .service('appealsShow', AppealsShowService).name;
