import { IPromise } from 'angular';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import api, { ApiService } from '../common/api/api.service';

export interface IAnswerSet {
    id: string;
    completed_at: string | null;
    questions: (IQuestion & { answer?: IAnswer })[];
}
export interface IAnswerSetApiPayload {
    id: string;
    completed_at: string | null;
    questions: IQuestion[];
    answers: IAnswer[];
}
export interface IQuestion {
    id: string;
    position: number;
    prompt: string;
    required: boolean;
    response_options: string[] | null;
}
export interface IAnswer {
    id: string;
    response: string;
    question: IQuestion;
}

const answerSetParams = {
    include: 'questions,answers',
    fields: {
        coaching_answer_sets: 'completed_at,questions,answers',
        questions: 'prompt,response_options,required,position',
        answers: 'response',
    },
};
export class CoachingQuestionsService {
    constructor(
        private api: ApiService,
        private accounts: AccountsService,
    ) {}
    private loadAnswerSets({
        accountListId,
        multiple,
        completed,
    }: {
        accountListId?: string;
        multiple: boolean;
        completed: boolean;
    }): IPromise<IAnswerSetApiPayload[]> {
        return this.api.get('coaching/answer_sets', {
            filter: {
                account_list_id: accountListId ?? this.api.account_list_id,
                completed,
            },
            sort: '-completed_at',
            ...(multiple ? {} : { limit: 1 }),
            ...answerSetParams,
        });
    }
    private loadOrCreateCurrentAnswerSet(): IPromise<IAnswerSetApiPayload> {
        return this.loadAnswerSets({ multiple: false, completed: false }).then((answerSets) => {
            if (answerSets[0]) {
                return answerSets[0];
            } else {
                return this.api.post({
                    url: 'coaching/answer_sets',
                    type: 'coaching_answer_sets',
                    data: {
                        ...answerSetParams,
                        data: {
                            type: 'coaching_answer_sets',
                            relationships: {
                                account_list: {
                                    data: {
                                        type: 'account_lists',
                                        id: this.api.account_list_id,
                                    },
                                },
                                organization: {
                                    data: {
                                        type: 'organizations',
                                        id: this.accounts.current.salary_organization,
                                    },
                                },
                            },
                        },
                    },
                    doSerialization: false,
                });
            }
        });
    }
    mapAnswerSet(answerSet: IAnswerSetApiPayload): IAnswerSet {
        return {
            id: answerSet.id,
            completed_at: answerSet.completed_at,
            questions: answerSet.questions
                .sort((a, b) => a.position - b.position)
                .map((question) => ({
                    ...question,
                    answer: answerSet.answers.find((answer) => answer.question.id === question.id),
                })),
        };
    }
    getCurrentAnswerSet(): IPromise<IAnswerSet> {
        return this.loadOrCreateCurrentAnswerSet().then(this.mapAnswerSet);
    }
    getCompletedAnswerSets(accountListId: string): IPromise<IAnswerSet[]> {
        return this.loadAnswerSets({ accountListId, multiple: true, completed: true }).then((answerSets) =>
            answerSets.map(this.mapAnswerSet),
        );
    }
    saveAnswer(answerSetId: string, questionId: string, answer: IAnswer): IPromise<IQuestion[]> {
        if (answer.id) {
            return this.api.put({
                url: `coaching/answers/${answer.id}`,
                type: 'coaching_answers',
                include: '',
                fields: {
                    coaching_answers: '',
                },
                data: {
                    data: {
                        type: 'coaching_answers',
                        attributes: {
                            response: answer.response,
                        },
                    },
                },
                doSerialization: false,
            });
        } else {
            return this.api.post({
                url: 'coaching/answers',
                type: 'coaching_answers',
                include: '',
                fields: {
                    coaching_answers: '',
                },
                data: {
                    data: {
                        type: 'coaching_answers',
                        attributes: {
                            response: answer.response,
                        },
                        relationships: {
                            question: {
                                data: {
                                    type: 'coaching_questions',
                                    id: questionId,
                                },
                            },
                            answer_set: {
                                data: {
                                    type: 'coaching_answer_sets',
                                    id: answerSetId,
                                },
                            },
                        },
                    },
                },
                doSerialization: false,
            });
        }
    }
}

export default angular
    .module('mpdx.coachingQuestions.service', [api, accounts])
    .service('coachingQuestions', CoachingQuestionsService).name;
