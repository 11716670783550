import { assign, isNil, map, omitBy } from 'lodash/fp';
import { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import { v1 as uuidv1 } from 'uuid';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../common/api/api.service';
import tasks, { TasksService } from '../../tasks.service';
import users, { UsersService } from '../../../common/users/users.service';

class BulkEditTaskController {
    comment: any;
    task: any;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private selectedTasks: any[],
        private accounts: AccountsService, // used in view
        private api: ApiService,
        private serverConstants: ServerConstantsService, // used in view
        private tasks: TasksService, // used in view
        private users: UsersService,
    ) {
        this.task = {};
    }
    save() {
        return this.bulkEdit(this.task, this.comment).then(() => {
            this.$scope.$hide();
        });
    }
    bulkEdit(model: any, comment?: string): ng.IPromise<any> {
        const tasks = map((id) => {
            let task = assign({ id }, model);
            if (comment) {
                task.comments = [
                    {
                        id: uuidv1(),
                        body: comment,
                        person: { id: this.users.current.id },
                    },
                ];
            }
            task = omitBy(isNil, task);
            if (task.no_date) {
                task.start_at = null;
            }
            return task;
        }, this.selectedTasks);
        return this.api.put('tasks/bulk', tasks).then((data) => {
            this.$rootScope.$emit('taskChange');
            return data;
        });
    }
}

export default angular
    .module('mpdx.tasks.bulkEdit.controller', [accounts, api, tasks, users])
    .controller('bulkEditTaskController', BulkEditTaskController).name;
