import 'angular-block-ui';
import { defaultTo } from 'lodash/fp';
import api, { ApiService } from '../../../../common/api/api.service';
import appealsShow, { AppealsShowService } from '../show.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../../../common/users/users.service';

class AskedController {
    appealId: string;
    blockUI: IBlockUIService;
    data: any[];
    meta: any;
    page: number;
    pageSize: number;
    watcher: () => void;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private appealsShow: AppealsShowService,
        private api: ApiService,
        blockUI: IBlockUIService,
        private gettext: ng.gettext.gettextFunction,
        private modal: ModalService,
        private serverConstants: ServerConstantsService, // used in view
        users: UsersService,
    ) {
        this.blockUI = blockUI.instances.get('appealsAsking');
        this.pageSize = defaultTo(25, users.getCurrentOptionValue('page_size_appeal'));
        this.page = 1;
    }
    $onInit() {
        this.watcher = this.$rootScope.$on('appeal:refresh', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher();
    }
    $onChanges() {
        this.appealsShow.deselectAll();
        if (this.appealId) {
            this.load();
        }
    }
    load(page: number = this.page): ng.IPromise<void> {
        this.blockUI.start();
        return this.api
            .get(`appeals/${this.appealId}/appeal_contacts`, {
                page,
                per_page: this.pageSize,
                include: 'contact',
                filter: {
                    pledged_to_appeal: false,
                },
                fields: {
                    contact: 'name,pledge_amount,pledge_currency,pledge_frequency',
                },
                sort: 'contact.name',
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug(`contacts not given page ${page}`, data);
                this.data = this.appealsShow.fixPledgeAmount(data);
                this.meta = data.meta;
                this.page = page;
                this.blockUI.reset();
            });
    }
    removeContact(refId: any): ng.IPromise<void> {
        const message = this.gettext('Are you sure you wish to remove this contact from the appeal?');
        return this.modal.confirm(message).then(() => {
            const successMessage = this.gettext('Contact removed from appeal');
            const errorMessage = this.gettext('Unable to remove contact from appeal');
            return this.api
                .delete(`appeals/${this.appealId}/appeal_contacts/${refId}`, undefined, successMessage, errorMessage)
                .then(() => {
                    this.$rootScope.$emit('appeal:refresh');
                });
        });
    }
    addPledge(contact: any): void {
        this.modal.open({
            template: require('../addPledge/add.html'),
            controller: 'addPledgeController',
            locals: {
                appealId: this.appealId,
                contact,
                pledge: {},
            },
        });
    }
    pageSizeChange(size) {
        this.pageSize = size;
        this.load(1);
    }
}

const Asked: ng.IComponentOptions = {
    template: require('./asked.html'),
    controller: AskedController,
    bindings: {
        appealId: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.show.asked.component', ['blockUI', appealsShow, api, modal, serverConstants, users])
    .component('appealAsked', Asked).name;
