import api, { ApiService } from '../../common/api/api.service';
import uiRouter from '@uirouter/angularjs';

class ExportDataRedirectController {
    constructor(
        private $window: ng.IWindowService,
        private api: ApiService,
        private $location: ng.ILocationService,
    ) {}
    $onInit() {
        const exportDataExportId = this.$location.search()?.exportID || null;
        if (exportDataExportId) {
            this.redirectToDownloadExportedData(exportDataExportId);
        }
    }
    redirectToDownloadExportedData(exportDataExportId) {
        if (!exportDataExportId) return;
        this.$window.location.replace(
            `${process.env.API_URL}/account_lists/${this.api.account_list_id}/exports/${encodeURIComponent(
                exportDataExportId,
            )}.xml?access_token=${this.$window.localStorage.getItem('token')}`,
        );
    }
}

const Personal = {
    controller: ExportDataRedirectController,
    template: require('./exportDataRedirect.html'),
};

export default angular
    .module('mpdx.preferences.exportDataRedirect.component', [uiRouter, api])
    .component('preferencesExportDataRedirect', Personal).name;
